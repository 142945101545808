import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import Web3 from 'web3';
import { App } from './Components/App';
import { assets, AssetsContext, socket, SocketContext } from './Context';
import './index.css';
import { Switch } from './routes';

export function getLibrary(provider) {
  return new Web3(provider);
}
const client = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={client}>
      <SocketContext.Provider value={socket}>
        <AssetsContext.Provider value={assets}>
          <Switch>
            <App />
          </Switch>
        </AssetsContext.Provider>
      </SocketContext.Provider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
