import { SocketContext } from '../../Context/index';
import { useState, useEffect, useContext } from 'react';

export function useLeaderboard() {
  const [leaderboard, setLeaderboard] = useState([]);
  const [meScore, setMeScore] = useState(0);
  const socket = useContext(SocketContext);

  useEffect(() => {
    if (!socket) return;

    socket.on('update', (data) => {
      setLeaderboard([...data?.leaderboard]);
      setMeScore(data.me.score);
    });
  }, [socket]);

  return { actualPlayerScore: meScore, leaderboard };
}
