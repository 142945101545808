import socketio from 'socket.io-client';
import React from 'react';
require('dotenv').config({ path: `.env` });
const socketURL = process.env.REACT_APP_SOCKET_URL;


export const socket = socketio(socketURL, {
  transports: ['websocket'],
  forceNew: false,
  autoConnect: false,
});

export const SocketContext = React.createContext();
export const AssetsContext = React.createContext();

const ASSET_NAMES = {
  fullship: 'FullBee.png',
  halfship: 'HalfBee.png',
  emptyship: 'EmptyBee.png',
  bullet: 'bullet.png',
  fly: 'fly.png',
  goblin: 'butterfly.png',
  boss: 'boss.png',
  obstacle: 'obstacle.png',
  heal: 'heal.png',
  speedup: 'speedup.png',
  takedmg: 'flamme.gif',
  background: 'Background/arena.png',
  aoe: 'aoe.png',
  shield: 'shield.png',
  dead: 'FullBeeDead.png',
  // teleport: '',
};

export const assets = {};

function downloadAsset(assetName) {
  return new Promise((resolve) => {
    const asset = new Image();
    asset.src = `/assets/${ASSET_NAMES[assetName]}`;
    asset.onload = () => {
      assets[assetName] = asset;
      resolve();
    };
  });
}

const downloadPromise = Promise.all(
  Object.keys(ASSET_NAMES).map((assetName) => downloadAsset(assetName)),
);

downloadPromise.then((data) => {
  return assets;
});
