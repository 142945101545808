import axios from 'redaxios';
import { addEtherscan, notify } from '../../Components/Web3/notify.lib';
require('dotenv').config({ path: `.env` });
const apiUrl = process.env.REACT_APP_API_URL;

export const useClaim = async ({ account, library }) => {
  return axios
    .get(`${apiUrl}/claim-tokens/${account}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(async (res) => {
      const {
        points_unclaimed,
        nonce_signature_claim,
        signature,
        smart_contract_address_token,
        abi_token,
      } = res.data.payload;

      console.log(
        `smart_contract_address ${smart_contract_address_token} and points_unclaimed = ${points_unclaimed} and nonce ${nonce_signature_claim} and signature ${signature} and web3 ${library} and accounts[0] ${account} and HoneyWaspContract  `,
      );

      const HoneyWaspContract = new library.eth.Contract(abi_token, smart_contract_address_token);
      library.eth.getGasPrice().then(async (gasPrice) => {
        console.log(gasPrice);
        const claimTx = await HoneyWaspContract.methods
          .claim(Math.trunc(points_unclaimed), nonce_signature_claim, signature)
          .send({ from: account, gasPrice })
          .on('transactionHash', function (hash) {
            const { emitter } = notify.hash(hash);
            emitter.on('all', addEtherscan);
            return hash;
          });
        return claimTx;
      });
    });
};

export const useClaimQuest = async ({ account, library, questId }) => {
  return axios
    .post(
      `${apiUrl}/quests/${account}/claim`,
      { questId },
      { headers: { 'Content-Type': 'application/json' } },
    )
    .then((res) => res.data.payload);
};
