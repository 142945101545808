import { useQuery } from 'react-query';
import useStore from '../../Stores/wallet';

export const useGetBalance = ({ abi_token, smart_contract_address_token }) => {
  const account = useStore((state) => state.account);
  const library = useStore((state) => state.library);

  return useQuery(
    ['useGetBalance', abi_token, smart_contract_address_token, account],
    async () => {
      try {
        const TokenContract = await new library.eth.Contract(
          abi_token,
          smart_contract_address_token,
        );
        const balance = await TokenContract?.methods?.balanceOf(account).call();
        return balance;
      } catch (error) {
        console.error(error);
      }
    },
    {
      // The query will not execute until the userId exists
      enabled: !!account && !!abi_token && !!smart_contract_address_token && !!library,
    },
  );
};
