import { useState, useEffect } from 'react';

const skills = [
  { name: 'Teleport', level: 1, selected: false, id: 1, image_url: './assets/teleport.png' },
  { name: 'Thorns', level: 50, selected: false, id: 2, image_url: './assets/aoe.png' },
  { name: 'Shield', level: 75, selected: false, id: 3, image_url: './assets/shield.png' },
];
export const Skills = ({ levelData : {level} /*, selectedSkill = (gears) => gears */ }) => {
  const [selection, setSelection] = useState([]);

  useEffect(() => {
    const skillsFound = skills.filter((skill) => skill.level <= level);
    setSelection(skillsFound);
  }, [level]);

  // const handleSelect = (id) => {
  //   const newSelections = selection.map((skill) =>
  //     skill.id === id ? { ...skill, selected: !skill.selected } : skill,
  //   );
  //   const userGears = newSelections.filter((skill) => skill.selected);

  //   if (userGears.length > 3) return;
  //   setSelection(newSelections);
  //   selectedSkill(userGears);
  // };

  const Skill = ({ skill }) => {
    const colors = ` ${skill?.selected ? 'animate-bounce' : ''}  bg-yellow-100 rounded-md`;
    return (
      <div>
        <div className={colors}>
          <img src={skill.image_url} alt={skill.image_url} className="max-h-24" />
        </div>
        <h3 className="mt-4 text-sm text-yellow-400">{skill.name}</h3>
      </div>
    );
  };

  return (
    <div className="mt-4 flex items-center justify-around w-96">
      {selection.map((skill, index) => (
        <div key={index}>
          <Skill skill={skill} />
        </div>
      ))}
    </div>
  );
};
