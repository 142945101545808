import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Annoucement } from '../../../Components/Annoucement';
import { EventsAnnouncement } from './EventsAnnouncement';
import { NavBar } from '../../../Components/NavBar';
import { Popup } from '../../../Components/Popup';
import { Slider } from '../../../Components/Slider';
import { useMessageSocket } from '../../../Hooks/Socketio/useMessageSocket';
import { Canvas } from '../Canvas';
import { Kills } from '../Kills';
import { Leaderboard } from '../Leaderboard';
import { Login } from '../Login';

export const Game = () => {
  const { message: messageSocket } = useMessageSocket();
  const [enter, setEnter] = useState(false);
  const [gameover, setGameover] = useState(false);
  const [score, setScore] = useState(0);
  const [message, setMessage] = useState('');
  const [monsterkill, setMonsterkill] = useState(null);
  const [showKills, setShowKills] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (showKills) setShowKills(false);
    }, 5000);
  }, [showKills]);

  useEffect(() => {
    setTimeout(() => {
      if (showKills) setShowKills(false);
    }, 5000);
  }, [showKills]);

  useEffect(() => {
    if (messageSocket) setEnter(false);
  }, [messageSocket]);

  useEffect(() => {
    return () => {
      setEnter(false);
      setGameover(false);
      setScore(0);
    };
  }, []);
  return (
    <>
      {gameover && <Popup score={score} message={message} />}
      {messageSocket && <Popup score={score} message={messageSocket} />}
      {!enter && !gameover && (
        <>
          <NavBar />
          <Login
            enterToArena={(isEnter = false) => {
              if (isEnter) {
                setGameover(false);
                setEnter(isEnter);
              }
            }}
          />
        </>
      )}
      {enter && (
        <div className="bg-gradient-radial to-gray-800 from-gray-900">
          <div className="m-2 absolute flex flex-col justify-start ">
            <Annoucement message="Press space to teleport" />
            {monsterkill && showKills && (
              <Kills killer={monsterkill.killer} killed={monsterkill.killed} />
            )}
          </div>
          <EventsAnnouncement />

          <div className="m-2 absolute top-0 right-0">
            {!isMobile && (
              <>
                <Leaderboard />
                <Slider />
              </>
            )}
          </div>
          <Canvas
            onGameOver={(isGameOver, score, message) => {
              setScore(Math.round(score));
              setMessage(message);
              setGameover(isGameOver);
              setEnter(false);
            }}
            onKillPlayer={(players) => {
              if (players) setMonsterkill(players);
              setShowKills(true);
            }}
          />
        </div>
      )}
    </>
  );
};
