import axios from 'redaxios';
import { useQuery } from 'react-query';
require('dotenv').config({ path: `.env` });
const apiUrl = process.env.REACT_APP_API_URL;

export const useGetUser = (pkey) => {
  return useQuery(
    ['useUser', pkey],
    () =>
      axios
        .get(`${apiUrl}/users/${pkey}`, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((res) => res.data.payload),
    {
      // The query will not execute until the userId exists
      enabled: !!pkey,
    },
  );
};
