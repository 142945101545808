import { useLeaderboard } from '../../../Hooks/Socketio/useLeaderboard';
import useStore from '../../../Stores/wallet';

export const Leaderboard = () => {
  const { leaderboard, actualPlayerScore } = useLeaderboard();
  const account = useStore((state) => state.account);

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Username
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Score
                  </th>
                </tr>
              </thead>
              <tbody>
                {leaderboard?.map((leaderboard, index) => (
                  <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {leaderboard.username}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {leaderboard.score}
                    </td>
                  </tr>
                ))}
                <tr className="bg-yellow-100">
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    YOU:
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {actualPlayerScore}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {account && (
            <div className="bg-grey-100 text-white">{`Wallet: 0x...${account.slice(35, 42)}`}</div>
          )}
        </div>
      </div>
    </div>
  );
};
