import { SocketContext } from '../../Context/index';
import { useState, useEffect, useContext } from 'react';
import CONSTANTS from '../../shared/constants.js';
const { MSG_TYPES } = CONSTANTS.default;

export function useMessageSocket() {
  const [message, setMessage] = useState(null);
  const [type, setType] = useState(null);
  const socket = useContext(SocketContext);

  useEffect(() => {
    if (!socket) return;

    socket.on(MSG_TYPES.MSG, (data) => {
      setMessage(data?.message);
      setType(data.type);
      if (data.type === 'BATTLE_ROYALE_WINNER') {
        socket.disconnect();
      }
    });
  }, [socket]);

  return { type, message };
}
