import { useQuery } from 'react-query';
import useStore from '../../Stores/wallet';
export const useGetLootPrices = (
  abi_honeyloothelper,
  smart_contract_address_honeyloothelper,
  abi_token,
  smart_contract_address_token,
) => {
  const library = useStore((state) => state.library);

  return useQuery(
    [
      'useGetLootPrices',
      abi_token,
      smart_contract_address_token,
      abi_honeyloothelper,
      smart_contract_address_honeyloothelper,
    ],
    async () => {
      const TokenContract = new library.eth.Contract(abi_token, smart_contract_address_token);
      const HoneyLootHelper = new library.eth.Contract(abi_honeyloothelper, smart_contract_address_honeyloothelper);
      const tokenSupply = await TokenContract.methods.totalSupply().call();
      let lootPrices = [];
      for (let i = 0; i < 4; i++) {
        lootPrices.push(Math.ceil(await HoneyLootHelper.methods.lootPriceByRarity(i, tokenSupply).call() / 10 ** 18));
      }
      return lootPrices;
    },
    {
      enabled:
        !!smart_contract_address_honeyloothelper &&
        !!abi_honeyloothelper &&
        !!abi_token &&
        !!smart_contract_address_token &&
        !!library,
    },
  );
};
