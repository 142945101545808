import CONSTANTS from '../../../shared/constants.js';

const {
  BULLET_RADIUS,
  BONUS_RADIUS,
  OBSTACLE_RADIUS,
  OBSTACLE_MAX_HP,
  PLAYER_RADIUS,
  PLAYER_MAX_HP,
  MAXIMUM_BULLET_RELOAD,
  MAP_SIZE,
} = CONSTANTS.default;

export function renderPlayer(me, player, canvas, assets) {
  const context = canvas.getContext('2d');

  const { x, y, direction } = player;
  const canvasX = canvas.width / 2 + x - me?.x;
  const canvasY = canvas.height / 2 + y - me?.y;

  // Draw ship
  context.save();
  context.translate(canvasX, canvasY);
  context.rotate(direction);
  let botToDraw;
  if (player?.type === 'BOT') {
    botToDraw = assets['fly'];
  } else if (player?.type === 'GOBLIN') {
    botToDraw = assets['goblin'];
  }
  if (botToDraw) {
    context.drawImage(
      botToDraw,
      -PLAYER_RADIUS,
      -PLAYER_RADIUS,
      PLAYER_RADIUS * 2.75,
      PLAYER_RADIUS * 2.75,
    );
  } else if (player?.type === 'BOSS') {
    context.drawImage(
      assets['boss'],
      -PLAYER_RADIUS * 3,
      -PLAYER_RADIUS * 3,
      PLAYER_RADIUS * 6,
      PLAYER_RADIUS * 6,
    );
  } else {
    let shipToDraw;
    if (!player.stackedBullets) {
      shipToDraw = assets['fullship'];
    }
    if (player.stackedBullets === MAXIMUM_BULLET_RELOAD) {
      shipToDraw = assets['fullship'];
    }
    if (player.stackedBullets < MAXIMUM_BULLET_RELOAD && player.stackedBullets >= 1) {
      shipToDraw = assets['halfship'];
    }

    if (player.stackedBullets < 1) {
      shipToDraw = assets['emptyship'];
    }
    if (player?.state === 'DEAD') shipToDraw = assets['dead'];
    context.drawImage(
      shipToDraw,
      -PLAYER_RADIUS,
      -PLAYER_RADIUS,
      PLAYER_RADIUS * 2,
      PLAYER_RADIUS * 2.5,
    );
    if (player?.animations?.aoe) {
      context.drawImage(
        assets['aoe'],
        -PLAYER_RADIUS * 4.5,
        -PLAYER_RADIUS * 4.5,
        PLAYER_RADIUS * 9,
        PLAYER_RADIUS * 9,
      );
    }
    if (player?.animations?.shield) {
      context.drawImage(
        assets['shield'],
        -PLAYER_RADIUS * 2.5,
        -PLAYER_RADIUS * 2.5,
        PLAYER_RADIUS * 5,
        PLAYER_RADIUS * 5,
      );
    }
  }

  context.restore();
  const startingPointXStatusBar = canvasX - 2.5 * PLAYER_RADIUS;
  const startingPointYStatusBar = canvasY + 3 * PLAYER_RADIUS;
  const thicknessBar = 10;
  const lengthBar = PLAYER_RADIUS * 5;
  const { gears } = player;
  if (player?.state !== 'DEAD') {
    // Draw health bar
    context.fillStyle = 'green';
    context.fillRect(
      startingPointXStatusBar,
      startingPointYStatusBar + thicknessBar,
      lengthBar,
      thicknessBar,
    );

    context.fillStyle = 'red';
    context.fillRect(
      startingPointXStatusBar + (lengthBar * player.hp) / PLAYER_MAX_HP,
      startingPointYStatusBar + thicknessBar,
      lengthBar * (1 - player.hp / PLAYER_MAX_HP),
      thicknessBar,
    );

    // Draw armor bar
    if (player?.armor > 0) {
      context.fillStyle = 'white';
      const MAX_ARMOR = gears?.max_armor || 100;
      context.fillRect(
        startingPointXStatusBar,
        startingPointYStatusBar,
        (lengthBar * (1 + player.armor)) / MAX_ARMOR,
        thicknessBar,
      );
    }
  }
  context.font = '28px serifs';
  if (player?.type === 'BOSS') {
    context.fillText(
      player.armor + ' / ' + gears?.max_armor,
      startingPointXStatusBar - PLAYER_RADIUS,
      canvasY - 5 * PLAYER_RADIUS,
      lengthBar + 2 * PLAYER_RADIUS,
      thicknessBar,
    );
  }

  // draw username
  if (player?.type === 'BOT' || player?.type === 'GOBLIN') {
    context.fillStyle = 'gray';
    context.font = '18px serifs';
  } else if (player?.type === 'HUMAN') {
    context.fillStyle = 'white';
    context.font = '30px serifs';
  } else if (player?.type === 'BOSS') {
    context.fillStyle = 'yellow';
    context.font = '24px serifs';
  }
  context.fillText(
    player.username,
    startingPointXStatusBar,
    startingPointYStatusBar + 3.5 * PLAYER_RADIUS,
    lengthBar + 2 * PLAYER_RADIUS,
    100,
  );

  // player.stackedBullets => number of bullet
  if (!player?.state?.startsWith('DEAD')) {
    for (let i = 0; i < player.stackedBullets; i++) {
      context.drawImage(
        assets['bullet'],
        startingPointXStatusBar + BULLET_RADIUS * i - 10, // TODO change PLAYER_RADIUS by new bullets asset radius
        startingPointYStatusBar - 1.5 * PLAYER_RADIUS,
        BULLET_RADIUS * 1.5,
        BULLET_RADIUS * 1.5,
      );
    }
  }
}

export function renderBackground(me, canvas, assets) {
  const context = canvas.getContext('2d');

  context.fillRect(0, 0, canvas.width, canvas.height);
  context.clearRect(0, 0, canvas.width, canvas.height);
  context.drawImage(
    assets['background'],
    canvas.width / 2 - me.x,
    canvas.height / 2 - me.y,
    MAP_SIZE,
    MAP_SIZE,
  );
  context.strokeRect(canvas.width / 2 - me.x, canvas.height / 2 - me.y, MAP_SIZE, MAP_SIZE);
}

export function renderBullet(me, bullet, canvas, assets) {
  const context = canvas.getContext('2d');

  const { x, y } = bullet;
  context.drawImage(
    assets['bullet'],
    canvas.width / 2 + x - me.x - BULLET_RADIUS,
    canvas.height / 2 + y - me.y - BULLET_RADIUS,
    BULLET_RADIUS * 1.5,
    BULLET_RADIUS * 1.5,
  );
}

export function renderObstacles(me, obstacles, canvas, assets) {
  const context = canvas.getContext('2d');
  obstacles.forEach((obstacle) => {
    context.fillStyle = 'white';
    const { x, y } = obstacle;
    const canvasX = canvas.width / 2 + x - me?.x;
    const canvasY = canvas.height / 2 + y - me?.y;

    const startingPointXStatusBar = canvasX - 2 * OBSTACLE_RADIUS;
    const startingPointYStatusBar = canvasY + 2 * OBSTACLE_RADIUS;
    const thicknessBar = 10;
    const lengthBar = OBSTACLE_RADIUS * 4;

    context.drawImage(
      assets['obstacle'],
      canvas.width / 2 + x - me.x - 2 * OBSTACLE_RADIUS,
      canvas.height / 2 + y - me.y - 2 * OBSTACLE_RADIUS,
      OBSTACLE_RADIUS * 4,
      OBSTACLE_RADIUS * 4,
    );
    // //Draw health bar for obstacle
    context.fillStyle = 'gray';
    context.fillRect(
      startingPointXStatusBar,
      startingPointYStatusBar + thicknessBar,
      lengthBar,
      thicknessBar,
    );

    context.fillStyle = 'black';
    context.fillRect(
      startingPointXStatusBar + (lengthBar * obstacle.hp) / OBSTACLE_MAX_HP,
      startingPointYStatusBar + thicknessBar,
      lengthBar * (1 - obstacle.hp / OBSTACLE_MAX_HP),
      thicknessBar,
    );
  });
}

export function renderBonuses(me, bonuses, type, canvas, assets) {
  const context = canvas.getContext('2d');

  bonuses.forEach((bonus) => {
    const { x, y } = bonus;
    context.fillStyle = 'blue';

    context.drawImage(
      assets[type],
      canvas.width / 2 + x - me.x - BONUS_RADIUS,
      canvas.height / 2 + y - me.y - BONUS_RADIUS,
      BONUS_RADIUS * 1.5,
      BONUS_RADIUS * 1.5,
    );
  });
}
