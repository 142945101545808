import { useEffect, useState } from 'react';
import { Layout } from '../../Components/Layout';
import { addEtherscan, notify } from '../../Components/Web3/notify.lib';
import { useSmartContractsData } from '../../Hooks/Api/useSmartContractsData';
import { useCheckAllowance } from '../../Hooks/SmartContracts/useCheckAllowance';
import { useGetLootPrices } from '../../Hooks/SmartContracts/useGetLootPrices';
import { approve } from '../../shared/approve';
import { gearsList as relatedProducts } from '../../shared/gearsLists.js';
import useStore from '../../Stores/wallet';

export const Marketplace = () => {
  const account = useStore((state) => state.account);
  const library = useStore((state) => state.library);
  const [approved, setApprouved] = useState(false);
  const [gearListWithPriceUpdated, setGearListWithPriceUpdated] = useState([]);
  const { data: smartContractData } = useSmartContractsData();
  const { data: lootPrices } = useGetLootPrices(
    smartContractData?.abi_honeyloothelper,
    smartContractData?.smart_contract_address_honeyloothelper,
    smartContractData?.abi_token,
    smartContractData?.smart_contract_address_token,
  );

  const { data: allowance, refetch } = useCheckAllowance(
    smartContractData?.smart_contract_address_nft,
    smartContractData?.abi_token,
    smartContractData?.smart_contract_address_token,
  );

  // const approve = async () => {
  //   if (smartContractData && library) {
  //     const { abi_token, smart_contract_address_token, smart_contract_address_nft } =
  //       smartContractData;

  //     const TokenContract = new library.eth.Contract(abi_token, smart_contract_address_token);

  //     await TokenContract.methods
  //       .approve(smart_contract_address_nft, library.utils.toWei('10000000', 'ether'))
  //       .send({ from: account })
  //       .on('transactionHash', function (hash) {
  //         const { emitter } = notify.hash(hash);
  //         emitter.on('all', addEtherscan);
  //       })
  //       .on('receipt', function (receipt) {
  //         if (receipt.status === true) {
  //           refetch();
  //         }
  //       });
  //   }
  // };

  useEffect(() => {
    if (!lootPrices) return;
    setGearListWithPriceUpdated(
      relatedProducts.map((gear) => {
        gear.price = lootPrices[gear.rarity] + ' HON';
        return gear;
      }),
    );
  }, [lootPrices]);

  const mint = async (_parts, _rarity) => {
    if (smartContractData) {
      const { smart_contract_address_nft, abi_nft } = smartContractData;

      const NFTContract = new library.eth.Contract(abi_nft, smart_contract_address_nft);

      await NFTContract.methods
        .claimItem(_parts, _rarity)
        .send({ from: account })
        .on('transactionHash', function (hash) {
          const { emitter } = notify.hash(hash);
          emitter.on('all', addEtherscan);
        });
    }
  };

  return (
    <Layout>
      <main className="max-w-7xl mx-auto sm:pt-16 sm:px-6 lg:px-8">
        <section className="mt-10 border-tpy-16 px-4 sm:px-0">
          <h2 id="related-heading" className="text-3xl font-bold text-white">
            Ultimate NFT
          </h2>

          <div className="mt-8 grid grid-cols-1 gap-y-12 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-3 xl:gap-x-8">
            {relatedProducts.map((product) => (
              <div key={product.id}>
                <div className="relative">
                  <div className="relative w-full h-124 rounded-lg overflow-hidden">
                    {/* <img
                      src={product.imageSrc}
                      alt={product.imageAlt}
                      className="w-full h-full object-center object-cover"
                    /> */}
                    <video
                      autoPlay
                      muted
                      loop
                      id={product.imageAlt}
                      className="flex-none  rounded-md object-center object-cover"
                    >
                      <source src={product.imageSrc} />
                    </video>
                  </div>
                  <div className="relative mt-4">
                    <h3 className="text-sm font-medium text-white">{product.name}</h3>
                    <p className="mt-1 text-sm text-gray-500">{product.description}</p>
                  </div>
                  <div className="absolute top-0 inset-x-0 h-72 rounded-lg p-4 flex items-end justify-end overflow-hidden">
                    <div
                      aria-hidden="true"
                      className="absolute inset-x-0 bottom-0 h-36 bg-gradient-to-t from-black opacity-50"
                    />
                    <p className="relative text-lg font-semibold text-white">{product.price}</p>
                  </div>
                </div>
                <div className="mt-6">
                  {(!allowance || approved) && (
                    <button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                      onClick={() =>
                        approve(
                          smartContractData,
                          library,
                          account,
                          refetch,
                          smartContractData?.smart_contract_address_nft,
                        )
                      }
                    >
                      Approve
                    </button>
                  )}

                  {allowance && (
                    <button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                      onClick={() => mint(product.smartcontract_part, product.rarity)}
                    >
                      Mint
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </section>
      </main>
    </Layout>
  );
};
