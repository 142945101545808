import { useState } from 'react';
import { useInterval } from '../../Hooks/useInterval';
export const Annoucement = ({ message }) => {
  const [delay, setDelay] = useState(true);
  useInterval(() => {
    setDelay(!delay);
  }, 2000);
  return (
    delay && (
      <div className="rounded-md m-2  mt-2">
        <div className="flex">
          <p className="text-xl text-white">{message}</p>
        </div>
      </div>
    )
  );
};
