import { addEtherscan, notify } from "../Components/Web3/notify.lib";

export const approve = async (smartContractData, library, account, refetch, allowed_smart_contract_address) => {
  if (smartContractData && library) {
    const { abi_token, smart_contract_address_token } =
      smartContractData;

    const TokenContract = new library.eth.Contract(abi_token, smart_contract_address_token);

    await TokenContract.methods
      .approve(allowed_smart_contract_address, library.utils.toWei('10000000000000', 'ether'))
      .send({ from: account })
      .on('transactionHash', function (hash) {
        const { emitter } = notify.hash(hash);
        emitter.on('all', addEtherscan);
      })
      .on('receipt', function (receipt) {
        if (receipt.status === true) {
          refetch();
        }
      });
  }
};
