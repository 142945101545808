import { XCircleIcon } from '@heroicons/react/solid';
import { useState } from 'react';

export const AlertError = ({ error, isShow = false }) => {
  const [show, setShow] = useState(isShow);
  setTimeout(function () {
    setShow(false);
  }, 5000);

  return (
    <>
      {show && (
        <div className="rounded-md bg-red-50 p-4 absolute  z-100">
          <div className="flex">
            <div className="flex-shrink-0">
              <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">
                {error?.data?.message ? error?.data?.message : error}
              </h3>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
