import React from 'react';
import { useState, useEffect } from 'react';

export const MiniCountDown = ({ message, startAt = new Date() }) => {
  const [days, setDays] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [hours, setHours] = useState(0);
  const [distance, setDistance] = useState(0);
  // Update the count down every 1 second
  const countDownDate = new Date(startAt)?.getTime();
  useEffect(() => {
    const timer = setInterval(function () {
      if (!countDownDate) return;

      // Get today's date and time
      const now = new Date().getTime();
      // Find the distance between now and the count down date
      setDistance(countDownDate - now);

      // Time calculations for days, hours, minutes and seconds
      setDays(Math.floor(distance / (1000 * 60 * 60 * 24)));
      setHours(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
      setMinutes(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
      setSeconds(Math.floor((distance % (1000 * 60)) / 1000));

      // If the count down is over, write some text
      // if (distanceLocal < 0) {
      //   clearInterval(timer);
      // }
    }, 1000);
    return () => clearTimeout(timer);
  }, [countDownDate, distance, startAt]);

  if (!startAt) return null;

  return (
    <div>
      {distance > 0 && (
        <div className="  flex flex-col justify-center items-center text-center">
          {message && (
            <p className="font-black pb-3 text-2xl sm:text-4xl lg:text-5xl  leading-9 text-center text-white">
              {message}
            </p>
          )}
          <div className="inline-flex space-x-2 sm:space-x-5 items-center justify-center">
            {days > 0 && (
              <p className="text-2xl sm:text-4xl lg:text-5xl font-black leading-9 text-center text-white">
                {days}
                <br />
                <b className="font-normal leading-normal text-base">DAYS</b>
              </p>
            )}
            {days > 0 && (
              <p className="font-black text-2xl sm:text-4xl lg:text-5xl  leading-9 text-center text-white">
                :
              </p>
            )}
            {hours > 0 && (
              <p className="text-2xl sm:text-4xl lg:text-5xl font-black leading-9 text-center text-white">
                {hours}
                <br />
                <b className="font-normal leading-normal text-base">HOURS</b>
              </p>
            )}
            {hours > 0 && (
              <p className="font-black text-2xl sm:text-4xl lg:text-5xl leading-9 text-center text-white">
                :
              </p>
            )}
            <p className="text-2xl sm:text-4xl lg:text-5xl font-black leading-9 text-center text-white">
              {minutes}
              <br />
              <b className="font-normal leading-normal text-base">MINUTES</b>
            </p>
            <p className="font-black text-2xl sm:text-4xl lg:text-5xl leading-9 text-center text-white">
              :
            </p>
            <p className="text-2xl sm:text-4xl lg:text-5xl font-black leading-9 text-center text-white">
              {seconds}
              <br />
              <b className="font-normal leading-normal text-base">SECONDS</b>
            </p>
          </div>
        </div>
      )}
    </div>
  );
};
