import { CheckIcon, EmojiSadIcon } from '@heroicons/react/outline';

export const QuestCard = ({ quest, onClaimQuest }) => {
  if (!quest) return null;

  return (
    <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
      <div className="px-6 py-8  text-center bg-yellow-100 sm:p-10 sm:pb-6">
        <div>
          <h3
            className="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-yellow-500 text-black"
            id="tier-standard"
          >
            {quest?.type} quests
          </h3>
        </div>
        <div className="mt-4   text-6xl font-extrabold">
          <p>{quest?.name}</p>
          <p className="mt-4 text-4xl text-green-600 animate-bounce font-extrabold">
            {quest?.amount} {quest?.reward_type}
          </p>
        </div>

        <p className="mt-5 text-lg text-gray-500">{quest.description}</p>
      </div>
      <div className="flex-1 flex flex-col justify-between px-6 pt-6 pb-8 bg-yellow-100 space-y-6 sm:p-10 sm:pt-6">
        <ul className="space-y-4">
          <li className="flex items-start">
            <div className="flex-shrink-0">
              {quest.players_to_kill > 0 ? (
                <EmojiSadIcon className="h-6 w-6 text-red-500" aria-hidden="true" />
              ) : (
                <CheckIcon className="h-6 w-6 text-green-500" aria-hidden="true" />
              )}
            </div>
            {quest.players_to_kill > 0 ? (
              <p className="ml-3 text-base text-gray-700">
                Missing {quest.players_to_kill} Players
              </p>
            ) : (
              <p className="ml-3 text-base text-gray-700">You kills all Flyes</p>
            )}
          </li>
          <li className="flex items-start">
            <div className="flex-shrink-0">
              {quest.bots_to_kill > 0 ? (
                <EmojiSadIcon className="h-6 w-6 text-red-500" aria-hidden="true" />
              ) : (
                <CheckIcon className="h-6 w-6 text-green-500" aria-hidden="true" />
              )}
            </div>
            {quest.bots_to_kill > 0 ? (
              <p className="ml-3 text-base text-gray-700">Missing {quest.bots_to_kill} Flyes</p>
            ) : (
              <p className="ml-3 text-base text-gray-700">You kills all Flyes!</p>
            )}
          </li>
          <li className="flex items-start">
            <div className="flex-shrink-0">
              {quest.goblins_to_kill > 0 ? (
                <EmojiSadIcon className="h-6 w-6 text-red-500" aria-hidden="true" />
              ) : (
                <CheckIcon className="h-6 w-6 text-green-500" aria-hidden="true" />
              )}
            </div>
            {quest.goblins_to_kill > 0 ? (
              <p className="ml-3 text-base text-gray-700">
                Missing {quest.goblins_to_kill} Butterflyes
              </p>
            ) : (
              <p className="ml-3 text-base text-gray-700">You kills all Butterflyes!</p>
            )}
          </li>
          <li className="flex items-start">
            <div className="flex-shrink-0">
              {quest.damage_to_deal_on_boss > 0 ? (
                <EmojiSadIcon className="h-6 w-6 text-red-500" aria-hidden="true" />
              ) : (
                <CheckIcon className="h-6 w-6 text-green-500" aria-hidden="true" />
              )}
            </div>
            {quest.damage_to_deal_on_boss > 0 ? (
              <p className="ml-3 text-base text-gray-700">
                Missing {quest.damage_to_deal_on_boss} damages to Boss
              </p>
            ) : (
              <p className="ml-3 text-base text-gray-700">You've dealt all the damage to Boss!</p>
            )}
          </li>
          <li className="flex items-start">
            <div className="flex-shrink-0">
              {quest.damage_to_deal > 0 ? (
                <EmojiSadIcon className="h-6 w-6 text-red-500" aria-hidden="true" />
              ) : (
                <CheckIcon className="h-6 w-6 text-green-500" aria-hidden="true" />
              )}
            </div>
            {quest.damage_to_deal > 0 ? (
              <p className="ml-3 text-base text-gray-700">
                Missing {quest.damage_to_deal} damages to deal overall
              </p>
            ) : (
              <p className="ml-3 text-base text-gray-700">You've dealt all damages</p>
            )}
          </li>
        </ul>
        {quest?.done && quest?.claimed && (
          <p className="ml-3 text-base text-gray-700">
            Your quest is done and {quest?.amount} {quest?.reward_type} has been added to your claim
            button.
          </p>
        )}

        {quest?.done && !quest?.claimed && (
          <div className="rounded-md ">
            <button
              onClick={() => onClaimQuest(quest?.id)}
              className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md  bg-yellow-500 hover:bg-yellow-600"
              aria-describedby="claim-standard"
            >
              Get Reward
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
