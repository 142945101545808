export const rarityTable = {
  0: 'Legendar',
  1: 'Epic',
  2: 'Rare',
  3: 'Common',
};

export const gearsList = [
  {
    id: 1,
    name: 'Weapon',
    smartcontract_part: 0,
    description: 'Gold weapon that decrease your fire cooldown by 75%',
    href: '#',
    imageSrc:
      'https://honeywasp.mypinata.cloud/ipfs/QmcpmdzQoJdmn85h6rAhmrsHXjSb5Fwimw1d9WZHLMZSmA/legendary_gun.mp4',
    gifSrc:
      'https://honeywasp.mypinata.cloud/ipfs/QmXKjYDrQcqju2KhVvroJgrP2PMVjyCrz8ECUhq7wizfrB/legendary_gun.gif',

    imageAlt: 'Weapon of our wasp.',
    price: '1 000 000 HON',
    rarity: 0,
    type: 'weapon',
    effect: { fire_cooldown_reduced: 75 },
  },
  {
    id: 2,
    name: 'Armor',
    smartcontract_part: 1,
    description: 'Gold armor that increase your armor by 1000 HP',
    href: '#',
    imageSrc:
      'https://honeywasp.mypinata.cloud/ipfs/QmcpmdzQoJdmn85h6rAhmrsHXjSb5Fwimw1d9WZHLMZSmA/legendary_shield.mp4',
    gifSrc:
      'https://honeywasp.mypinata.cloud/ipfs/QmXKjYDrQcqju2KhVvroJgrP2PMVjyCrz8ECUhq7wizfrB/legendary_shield.gif',
    imageAlt: 'Armor of our wasp.',
    price: '1 000 000 HON',
    rarity: 0,
    type: 'armor',
    effect: { max_armor: 1000 },
  },
  {
    id: 3,
    name: 'Utility',
    smartcontract_part: 2,
    description: 'Gold amulet that increase speed by 300',
    href: '#',
    imageSrc:
      'https://honeywasp.mypinata.cloud/ipfs/QmcpmdzQoJdmn85h6rAhmrsHXjSb5Fwimw1d9WZHLMZSmA/legendary_wings.mp4',
    gifSrc:
      'https://honeywasp.mypinata.cloud/ipfs/QmXKjYDrQcqju2KhVvroJgrP2PMVjyCrz8ECUhq7wizfrB/legendary_wings.gif',

    imageAlt: 'Utility of our wasp.',
    price: '1 000 000 HON',
    rarity: 0,
    type: 'utility',
    effect: { speed: 300 },
  },
  {
    id: 4,
    name: 'Weapon',
    smartcontract_part: 0,
    description: 'Gold weapon that decrease your fire cooldown by 50%',
    href: '#',
    imageSrc:
      'https://honeywasp.mypinata.cloud/ipfs/QmcpmdzQoJdmn85h6rAhmrsHXjSb5Fwimw1d9WZHLMZSmA/epic_gun.mp4',
    gifSrc:
      'https://honeywasp.mypinata.cloud/ipfs/QmXKjYDrQcqju2KhVvroJgrP2PMVjyCrz8ECUhq7wizfrB/epic_gun.gif',

    imageAlt: 'Weapon of our wasp.',
    price: '200 000 HON',
    rarity: 1,
    type: 'weapon',
    effect: { fire_cooldown_reduced: 50 },
  },
  {
    id: 5,
    name: 'Armor',
    smartcontract_part: 1,
    description: 'Gold armor that increase your armor by 750 HP',
    href: '#',
    imageSrc:
      'https://honeywasp.mypinata.cloud/ipfs/QmcpmdzQoJdmn85h6rAhmrsHXjSb5Fwimw1d9WZHLMZSmA/epic_shield.mp4',
    gifSrc:
      'https://honeywasp.mypinata.cloud/ipfs/QmXKjYDrQcqju2KhVvroJgrP2PMVjyCrz8ECUhq7wizfrB/epic_shield.gif',

    imageAlt: 'Armor of our wasp.',
    price: '200 000 HON',
    rarity: 1,
    type: 'armor',
    effect: { max_armor: 750 },
  },
  {
    id: 6,
    name: 'Utility',
    smartcontract_part: 2,
    description: 'Gold amulet that increase speed by 200',
    href: '#',
    imageSrc:
      'https://honeywasp.mypinata.cloud/ipfs/QmcpmdzQoJdmn85h6rAhmrsHXjSb5Fwimw1d9WZHLMZSmA/epic_wings.mp4',
    gifSrc:
      'https://honeywasp.mypinata.cloud/ipfs/QmXKjYDrQcqju2KhVvroJgrP2PMVjyCrz8ECUhq7wizfrB/epic_wings.gif',

    imageAlt: 'Utility of our wasp.',
    price: '200 000 HON',
    rarity: 1,
    type: 'utility',
    effect: { speed: 200 },
  },
  {
    id: 7,
    name: 'Weapon',
    smartcontract_part: 0,
    description: 'Gold weapon that decrease your fire cooldown by 30%',
    href: '#',
    imageSrc:
      'https://honeywasp.mypinata.cloud/ipfs/QmcpmdzQoJdmn85h6rAhmrsHXjSb5Fwimw1d9WZHLMZSmA/rare_gun.mp4',
    gifSrc:
      'https://honeywasp.mypinata.cloud/ipfs/QmXKjYDrQcqju2KhVvroJgrP2PMVjyCrz8ECUhq7wizfrB/rare_gun.gif',

    imageAlt: 'Weapon of our wasp.',
    price: '50 000 HON',
    rarity: 2,
    type: 'weapon',
    effect: { fire_cooldown_reduced: 30 },
  },
  {
    id: 8,
    name: 'Armor',
    smartcontract_part: 1,
    description: 'Gold armor that increase your armor by 500 HP',
    href: '#',
    imageSrc:
      'https://honeywasp.mypinata.cloud/ipfs/QmcpmdzQoJdmn85h6rAhmrsHXjSb5Fwimw1d9WZHLMZSmA/rare_shield.mp4',
    gifSrc:
      'https://honeywasp.mypinata.cloud/ipfs/QmXKjYDrQcqju2KhVvroJgrP2PMVjyCrz8ECUhq7wizfrB/rare_shield.gif',

    imageAlt: 'Armor of our wasp.',
    price: '50 000 HON',
    rarity: 2,
    type: 'armor',
    effect: { max_armor: 500 },
  },
  {
    id: 9,
    name: 'Utility',
    smartcontract_part: 2,
    description: 'Gold amulet that increase speed by 150',
    href: '#',
    imageSrc:
      'https://honeywasp.mypinata.cloud/ipfs/QmcpmdzQoJdmn85h6rAhmrsHXjSb5Fwimw1d9WZHLMZSmA/rare_wings.mp4',
    gifSrc:
      'https://honeywasp.mypinata.cloud/ipfs/QmXKjYDrQcqju2KhVvroJgrP2PMVjyCrz8ECUhq7wizfrB/rare_wings.gif',

    imageAlt: 'Utility of our wasp.',
    price: '50 000 HON',
    rarity: 2,
    type: 'utility',
    effect: { speed: 150 },
  },
  {
    id: 10,
    name: 'Weapon',
    smartcontract_part: 0,
    description: 'Gold weapon that decrease your fire cooldown by 15%',
    href: '#',
    imageSrc:
      'https://honeywasp.mypinata.cloud/ipfs/QmcpmdzQoJdmn85h6rAhmrsHXjSb5Fwimw1d9WZHLMZSmA/common_gun.mp4',
    gifSrc:
      'https://honeywasp.mypinata.cloud/ipfs/QmXKjYDrQcqju2KhVvroJgrP2PMVjyCrz8ECUhq7wizfrB/common_gun.gif',

    imageAlt: 'Weapon of our wasp.',
    price: '10 000 HON',
    rarity: 3,
    type: 'weapon',
    effect: { fire_cooldown_reduced: 15 },
  },
  {
    id: 11,
    name: 'Armor',
    smartcontract_part: 1,
    description: 'Gold armor that increase your armor by 250 HP',
    href: '#',
    imageSrc:
      'https://honeywasp.mypinata.cloud/ipfs/QmcpmdzQoJdmn85h6rAhmrsHXjSb5Fwimw1d9WZHLMZSmA/common_shield.mp4',
    gifSrc:
      'https://honeywasp.mypinata.cloud/ipfs/QmXKjYDrQcqju2KhVvroJgrP2PMVjyCrz8ECUhq7wizfrB/common_shield.gif',

    imageAlt: 'Armor of our wasp.',
    price: '10 000 HON',
    rarity: 3,
    type: 'armor',
    effect: { max_armor: 250 },
  },
  {
    id: 12,
    name: 'Utility',
    smartcontract_part: 2,
    description: 'Gold amulet that increase speed by 100',
    href: '#',
    imageSrc:
      'https://honeywasp.mypinata.cloud/ipfs/QmcpmdzQoJdmn85h6rAhmrsHXjSb5Fwimw1d9WZHLMZSmA/common_wings.mp4',
    gifSrc:
      'https://honeywasp.mypinata.cloud/ipfs/QmXKjYDrQcqju2KhVvroJgrP2PMVjyCrz8ECUhq7wizfrB/common_wing.gif',

    imageAlt: 'Utility of our wasp.',
    price: '10 000 HON',
    rarity: 3,
    type: 'utility',
    effect: { speed: 100 },
  },
];
