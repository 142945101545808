/* This example requires Tailwind CSS v2.0+ */
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const Faq = () => {

  const faqs = [
        {
        question: `How to mint ?`,
        answer: `You will just need to connect your wallet on the website, choose the amount of NFT to mint then you will have to validate the transaction on your wallet.`,
        },
        {
        question: `What will the mint price be ?`,
        answer: `The public sale will be in the form of a Dutch Action (the price drops every minute!) It will start at 2000 Matic on 3rd June and will reach the lowest price of 50 Matic on 6 June )`,
        },
        {
            question: `When will the boxes open?`,
            answer: `The boxes will open and show your nft once the Public Sale is over.`,
        },
    ]

  return (
    <div id="help">
      <div className="max-w-7xl mx-auto py-12 px-6 sm:py-16 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto divide-y-2 divide-yellow-200">
          <h2 className="text-center text-3xl font-extrabold text-yellow-500 sm:text-5xl">
            Frequently asked questions
          </h2>
          <dl className="mt-6 space-y-6 divide-y divide-yellow-200">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="text-left w-full flex justify-between items-start text-yellow-400">
                        <span className="font-medium text-yellow-500">
                          {faq.question}
                        </span>
                        <span className="ml-6 h-7 flex items-center">
                          <ChevronDownIcon
                            className={classNames(
                              open ? "-rotate-180" : "rotate-0",
                              "h-6 w-6 transform"
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-yellow-200">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}