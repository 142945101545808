import { CheckCircleIcon } from '@heroicons/react/solid';
import { useState } from 'react';

export const AlertSuccess = ({ message, isShow = false }) => {
  const [show, setShow] = useState(isShow);

  setTimeout(function () {
    setShow(false);
  }, 5000);

  return (
    <>
      {show && (
        <div className="rounded-md bg-green-50 p-4 absolute  z-100">
          <div className="flex">
            <div className="flex-shrink-0">
              <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-green-800">{message || 'done'}</h3>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
