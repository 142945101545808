import useStore from '../../Stores/wallet';
import { useConnectUser } from '../../Hooks/Api/useConnectUser';
import { useEffect } from 'react';

export const Wallet = () => {
  const account = useStore((state) => state.account);
  const userWallet = useStore((state) => state.user_wallet);

  useEffect(() => {
    async function onLoad() {
      // get the selectedWallet value from local storage
      const previouslySelectedWallet = window.localStorage.getItem('selectedWallet');
      // call wallet select with that value if it exists
      if (previouslySelectedWallet != null) {
        userWallet.walletSelect(previouslySelectedWallet).then(() => {
          //   userWallet.walletCheck();
        });
      }
    }
    onLoad();
  }, []);

  useEffect(() => {
    if (account) {
      MakeConnection(account);
    }
  }, [account]);

  async function MakeConnection(publicKey) {
    await useConnectUser(publicKey);
  }

  async function connect() {
    try {
      await userWallet.walletSelect();
      await userWallet.walletCheck();
    } catch (err) {
      console.log(err);
    }
  }

  async function disconnect() {
    try {
      await userWallet.walletReset();
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      {!account && (
        <button
          onClick={connect}
          className="flex  items-center justify-center p-2 border border-transparent text-base font-medium rounded-md text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
        >
          Connect Wallet
        </button>
      )}
    </>
  );
};
