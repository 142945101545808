import { useState, useEffect } from 'react';
import { useGetNFT } from '../../../../Hooks/SmartContracts/useGetNFT';
import { useSmartContractsData } from '../../../../Hooks/Api/useSmartContractsData';
import { gearsList, rarityTable } from '../../../../shared/gearsLists.js';

const armor = {
  max_armor: 500,
  name: '+1000 Armor',
  imageSrc:
    'https://64.media.tumblr.com/bf3a636d170ec0f46752a84e67f7e5c7/tumblr_oy1cszVNX01r03qmqo1_500.png',
  imageAlt: 'Increase Armor by 1000',
};
const life = {
  name: '+0 Life',
  imageSrc:
    'https://i1.wp.com/purorpg.com/wp-content/uploads/2020/07/potion-healing-2.jpg?resize=564%2C564&ssl=1',
  imageAlt: 'Increase Life',
};
const exp = {
  boost_experience: 50,
  name: '+50% experience',
  imageSrc:
    'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/4e330b58-6392-4188-92c9-0624b44e39ea/dchp7io-1de937fc-50cc-49a0-ac7a-1fad8d9b6153.png?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcLzRlMzMwYjU4LTYzOTItNDE4OC05MmM5LTA2MjRiNDRlMzllYVwvZGNocDdpby0xZGU5MzdmYy01MGNjLTQ5YTAtYWM3YS0xZmFkOGQ5YjYxNTMucG5nIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.Klvs4wPUhumkfbX_WKaOx6LEqHubqzrY_La6-L3gUXY',
  imageAlt: 'Boost your experience',
};

const fire_cooldown_reduced = {
  fire_cooldown_reduced: 25,
  name: '-25% fire cooldown',
  imageSrc: 'https://i.pinimg.com/564x/b2/63/f7/b263f7702d150a8e2d80b237b5d29089.jpg',
  imageAlt: 'Reduce fire cooldown by 25%',
};

const speed = {
  speed: 500,
  name: '+500 speed',
  imageSrc: 'https://i.pinimg.com/564x/b2/63/f7/b263f7702d150a8e2d80b237b5d29089.jpg',
  imageAlt: 'Increase speed by 500',
};

const gears = [
  { ...speed, id: Math.floor(Math.random() * 10000) },
  { ...speed, id: Math.floor(Math.random() * 10000) },
  { ...speed, id: Math.floor(Math.random() * 10000) },
  { ...speed, id: Math.floor(Math.random() * 10000) },
  { ...fire_cooldown_reduced, id: Math.floor(Math.random() * 10000) },
  { ...fire_cooldown_reduced, id: Math.floor(Math.random() * 10000) },
  { ...fire_cooldown_reduced, id: Math.floor(Math.random() * 10000) },
  { ...fire_cooldown_reduced, id: Math.floor(Math.random() * 10000) },
  { ...fire_cooldown_reduced, id: Math.floor(Math.random() * 10000) },
  { ...fire_cooldown_reduced, id: Math.floor(Math.random() * 10000) },
  { ...fire_cooldown_reduced, id: Math.floor(Math.random() * 10000) },
  { ...armor, id: Math.floor(Math.random() * 10000) },
  { ...armor, id: Math.floor(Math.random() * 10000) },
  { ...armor, id: Math.floor(Math.random() * 10000) },
  { ...armor, id: Math.floor(Math.random() * 10000) },
  { ...life, id: Math.floor(Math.random() * 10000) },
  { ...life, id: Math.floor(Math.random() * 10000) },
  { ...life, id: Math.floor(Math.random() * 10000) },
  { ...life, id: Math.floor(Math.random() * 10000) },
  { ...exp, id: Math.floor(Math.random() * 10000) },
  { ...exp, id: Math.floor(Math.random() * 10000) },
  { ...exp, id: Math.floor(Math.random() * 10000) },
];

export const Gears = ({ selectedGears = (gears) => gears }) => {
  const [selection, setSelection] = useState([]);
  const { data: smartContractData } = useSmartContractsData();
  const { data: nfts } = useGetNFT({ ...smartContractData });

  useEffect(() => {
    if (nfts) {
      const gearsFound = nfts.map((gear) => {
        return { ...gear, selected: false };
      });

      setSelection(gearsFound);
    }
  }, [nfts]);

  const handleSelect = (id) => {
    const newSelections = selection.map((gear) =>
      gear.id === id ? { ...gear, selected: !gear.selected } : gear,
    );
    let userGears = newSelections.filter((gear) => gear.selected);
    if (userGears?.length > 3) {
      return;
    }
    setSelection(newSelections);
    userGears = formatGearsArray(userGears);
    selectedGears(userGears);
  };

  const formatGearsArray = (gearsUnformatted) => {
    return gearsUnformatted.map((gearUnformatted) => {
      const { value: rarity } = gearUnformatted.metadata.attributes.find((attribute) => {
        if (attribute.trait_type === 'Rarity') return attribute.value;
      });
      const { value: type } = gearUnformatted.metadata.attributes.find((attribute) => {
        if (attribute.trait_type === 'Type') return attribute.value;
      });
      const gear = gearsList.find(
        (gear) => rarityTable[gear.rarity] === rarity && gear.type === type,
      );
      return gear.effect;
    });
  };

  const Gear = ({ gear, i }) => {
    const colors = ` ${
      gear?.selected ? 'animate-bounce' : ''
    } aspect-h-1 bg-yellow-100 rounded-md overflow-hidden group-hover:opacity-80`;
    return (
      <>
        <div className={colors}>
          <img
            src={gear.metadata.animation_url}
            alt={gear.metadata.animation_url}
            className="object-center object-cover"
          />
        </div>
        <h3 className="mt-4 text-sm text-yellow-400">
          <button onClick={() => handleSelect(gear.id)}>
            <span className="absolute inset-0" />
            {gear.name}
          </button>
        </h3>
      </>
    );
  };

  return (
    <div>
      {nfts && nfts?.length != 0 && (<p className="text-base font-bold sm:pb-1 pb-10">SELECT YOUR GEAR:</p>)}
      <div className="mt-4 grid grid-cols-2 gap-x-6 gap-y-10 sm:grid-cols-3 sm:gap-y-0 lg:grid-cols-4 lg:gap-x-8 xl:grid-cols-6">
        {selection.map((gear, index) => (
          <div key={index} className="group relative">
            <Gear gear={gear} i={gear.id}></Gear>
          </div>
        ))}
      </div>
    </div>
  );
};
