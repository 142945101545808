import Notify from 'bnc-notify';
export const notify = Notify({
  dappId: process.env.BLOCKNATIVE_NOTIFY_API || 'a16817a8-95c2-474e-82c0-3bf375e22b31', // [String] The API key created by step one above
  // [Integer] The Ethereum network ID your Dapp uses.
  networkId:
    process.env.NODE_ENV === 'production'
      ? parseInt(process.env.REACT_APP_NETWORK_ID)
      : parseInt(process.env.REACT_APP_TEST_NETWORK_ID),
});

export function addEtherscan(transaction) {
  return {
    link:
      process.env.NODE_ENV === 'production'
        ? `${process.env.REACT_APP_URL_EXPLORER}${transaction.hash}`
        : `${process.env.REACT_APP_URL_TESTNET_EXPLORER}${transaction.hash}`,
  };
}
