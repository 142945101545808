import { SocketContext } from '../../Context/index';
import { useState, useEffect, useContext } from 'react';
import CONSTANTS from '../../shared/constants.js';
const { MSG_TYPES } = CONSTANTS.default;

export function useStartAt() {
  const [startAt, setStartAt] = useState(0);
  const socket = useContext(SocketContext);

  useEffect(() => {
    if (!socket) return;

    socket.on(MSG_TYPES.MSG, (data) => {
      if (data.type === 'BATTLE_GROUND_TIMELEFT') setStartAt(new Date(data.start_at));
      if (data.type === 'BOSS_TIMELEFT') setStartAt(new Date(data.start_at));
    });
  }, [socket]);

  return { startAt };
}
