import { useQuery } from 'react-query';
import useStore from '../../Stores/wallet';
import { decode } from 'base-64';

export const useGetNFT = ({ smart_contract_address_nft, abi_nft }) => {
  const account = useStore((state) => state.account);
  const library = useStore((state) => state.library);

  return useQuery(
    ['useGetNFT', account, abi_nft],
    async () => {
      const NFTContract = new library.eth.Contract(abi_nft, smart_contract_address_nft);
      const numberOfLoot = await NFTContract.methods.balanceOf(account).call();

      const NFTOwnerByUser = await Promise.all(
        [...Array(Number(numberOfLoot)).keys()]?.map(async (i) => {
          const token = await NFTContract.methods.tokenOfOwnerByIndex(account, i).call();
          const metadataEncoded = await NFTContract.methods.tokenURI(token).call();
          const metadata = JSON.parse(
            decode(metadataEncoded.replace('data:application/json;base64,', '')),
          );

          return {
            id: token,
            uri: metadata.image,
            metadata,
          };
        }),
      );
      return NFTOwnerByUser;
    },
    {
      // The query will not execute until the userId exists
      enabled: !!account && !!abi_nft && !!library,
    },
  );
};
