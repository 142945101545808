import { Layout } from '../../Components/Layout';
import { WalletNotConnected } from '../../Components/WalletNotConnected';
import { useGetLevel } from '../../Hooks/SmartContracts/useGetLevel';
import { useSmartContractsData } from '../../Hooks/Api/useSmartContractsData';
import useStore from '../../Stores/wallet';
import { Leveling } from './Leveling';
import { approve } from '../../shared/approve';
import { useCheckAllowance } from '../../Hooks/SmartContracts/useCheckAllowance';
import { addEtherscan, notify } from '../../Components/Web3/notify.lib';

export const Dashboard = () => {
  const account = useStore((state) => state.account);
  const library = useStore((state) => state.library);
  const { data: smartContractData } = useSmartContractsData();
  const { data: userData, refetch: refetchGetLevel } = useGetLevel({ ...smartContractData });
  const { data: allowance, refetch } = useCheckAllowance(
    smartContractData?.smart_contract_address_level,
    smartContractData?.abi_token,
    smartContractData?.smart_contract_address_token,
  );
  if (!account) {
    return (
      <Layout>
        <WalletNotConnected text1="Missing your wallet" text2=" Connect your wallet" />
      </Layout>
    );
  }

  const levelUp = async () => {
    if (smartContractData) {
      const { smart_contract_address_level, abi_level } = smartContractData;

      const LevelContract = new library.eth.Contract(abi_level, smart_contract_address_level);
      library.eth.getGasPrice().then(async (gasPrice) => {
        await LevelContract.methods
          .leveling()
          .send({ from: account, gasPrice })
          .on('transactionHash', function (hash) {
            const { emitter } = notify.hash(hash);
            emitter.on('all', addEtherscan);
          })
          .on('confirmation', function (confirmationNumber, receipt) {
            refetchGetLevel();
          })
      });
    }
  };

  return (
    <Layout>
      <div className="text-white">
        <div className="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
          <div className="lg:grid lg:grid-cols-2 lg:gap-x-8 lg:items-start">
            {/* Image gallery */}
            <div as="div" className="flex flex-col-reverse">
              {/* Image selector */}

              <div className="w-full aspect-w-1 aspect-h-1">
                <img
                  src="./assets/BeeKnightBackgroundbg.png"
                  alt="your wasp"
                  className="w-full h-full object-center object-contain sm:rounded-lg"
                />
              </div>
            </div>

            {/* Product info */}
            <div className="mt-10 px-4 sm:px-0 sm:mt-16 lg:mt-0">
              {account && (
                <h1 className="text-3xl font-extrabold tracking-tight ">
                  {account?.slice(0, 10)}...
                </h1>
              )}
              <div className="mt-3">
                <p className="text-3xl ">Level: {userData?.level}</p>
              </div>

              {/* Reviews */}
              <div className="mt-3">{<Leveling level={userData?.level} />}</div>

              <div className="mt-6">
                <h3 className="sr-only">Description</h3>

                <div className="text-base  space-y-6">Burn HON to level up and get new powers</div>
              </div>
              {!allowance && (
                <div className="mt-10 flex sm:flex-col1">
                  <button
                    onClick={() =>
                      approve(
                        smartContractData,
                        library,
                        account,
                        refetch,
                        smartContractData?.smart_contract_address_level,
                      )
                    }
                    className="max-w-xs flex-1 bg-green-600 border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-green-500 sm:w-full"
                  >
                    APPROVE
                  </button>
                </div>
              )}
              {allowance && userData?.cost && (
                <div className="mt-10 flex sm:flex-col1">
                  <button
                    onClick={() => levelUp()}
                    className="max-w-xs flex-1 bg-green-600 border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-green-500 sm:w-full"
                  >
                    Next Level : {Math.ceil(userData?.cost / 10 ** 18)} HON
                  </button>
                </div>
              )}
              <p>{userData?.test}</p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
