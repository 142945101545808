import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { AlertError, AlertSuccess } from '../../Components/Alerts';
import { Layout } from '../../Components/Layout';
import { useClaimQuest } from '../../Hooks/Api/useClaim';
import { useQuests } from '../../Hooks/Api/useQuests';
import useStore from '../../Stores/wallet';
import { QuestCard } from './QuestCard';

export const Quests = () => {
  const account = useStore((state) => state.account);
  const library = useStore((state) => state.library);

  const { mutate, isError, error, isSuccess } = useMutation(useClaimQuest);
  const { data: quests = [], refetch } = useQuests(account);

  useEffect(() => {
    if (isSuccess) refetch();
  }, [isSuccess]);

  if (!account) {
    return (
      <Layout>
        <div className="pt-12 sm:pt-16 lg:pt-24">
          <div className="max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8">
            <div className="max-w-3xl mx-auto space-y-2 lg:max-w-none">
              <h2 className="text-lg leading-6 font-semibold text-gray-300 uppercase tracking-wider">
                missing your wallet
              </h2>
              <p className="text-3xl font-extrabold text-white sm:text-4xl lg:text-5xl">
                Connect your wallet to see your quests
              </p>
              <p className="text-xl text-gray-300">Here you can see and valid your quests</p>
            </div>
          </div>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div>
        <div className="pt-12 sm:pt-16 lg:pt-24">
          <div className="max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8">
            <div className="max-w-3xl mx-auto space-y-2 lg:max-w-none">
              <h2 className="text-lg leading-6 font-semibold text-gray-300 uppercase tracking-wider">
                More HON!
              </h2>
              <p className="text-3xl font-extrabold text-white sm:text-4xl lg:text-5xl">Quests</p>
              <p className="text-xl text-gray-300">Here you can see and valid your quests</p>
              {isError && <AlertError error={error} isShow={isError} />}
              {isSuccess && (
                <AlertSuccess
                  message={'Your points have been added to your claim button.'}
                  isShow={isSuccess}
                />
              )}
            </div>
          </div>
        </div>
        <div className="mt-8 pb-12 sm:mt-12 sm:pb-16 lg:mt-16 lg:pb-24">
          <div className="relative">
            <div className="absolute inset-0 h-3/4 bg-gray-900" />

            <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="max-w-md mx-auto space-y-4 lg:max-w-5xl lg:grid lg:grid-cols-2 lg:gap-5 lg:space-y-0">
                {quests?.map((quest) => (
                  <div key={quest.id}>
                    <QuestCard
                      quest={quest}
                      onClaimQuest={(questId) => {
                        console.log({ questId, account, library });
                        if (account && library && questId) mutate({ account, library, questId });
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
