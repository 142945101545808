import { Disclosure, Menu, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { Fragment } from 'react';
import { useMutation } from 'react-query';
import { NavLink } from 'react-router-dom';
import { useGetUnclaimedPoints } from '../../Hooks/Api/useUnclaimedPoints';
import { useClaim } from '../../Hooks/Api/useClaim';
import { useGetBalance } from '../../Hooks/SmartContracts/useGetBalance';
import { useRemainingGames } from '../../Hooks/Api/useRemainingGames';
import { useSmartContractsData } from '../../Hooks/Api/useSmartContractsData';
import useStore from '../../Stores/wallet';
import { AlertError } from '../Alerts';
import { Wallet } from '../Wallet';
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const NavBar = () => {
  const account = useStore((state) => state.account);
  const library = useStore((state) => state.library);
  const userWallet = useStore((state) => state.user_wallet);

  const { data: smartContractData } = useSmartContractsData();
  const { data: balance } = useGetBalance({ ...smartContractData });
  const { data: remainingGames } = useRemainingGames(account);
  const { data: points_unclaimed } = useGetUnclaimedPoints(account);
  const { mutate, isError, error } = useMutation(useClaim);

  async function disconnect() {
    try {
      await userWallet.walletReset();
    } catch (err) {
      console.log(err);
    }
  }

  const claim = () => {
    if (account && library) mutate({ account, library });
  };

  return (
    <header className="relative z-10">
      <Disclosure as="nav" className="bg-gray-800">
        {({ open }) => (
          <>
            {isError && <AlertError error={error} isShow={isError} />}
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex items-center justify-between h-16">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <NavLink to="/">
                      <span className="sr-only">HoneyWasp</span>
                      <img className="h-8 w-auto" src="/assets/logo.png" alt="" />
                    </NavLink>
                  </div>
                  <div className="hidden sm:block sm:ml-6">
                    <div className="flex flex-row space-x-4">
                      {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
                      <NavLink
                        className="flex  items-center text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                        to="/"
                      >
                        <span className="text-white">Play</span>
                      </NavLink>
                    </div>
                  </div>
                  <div className="hidden sm:block sm:ml-6">
                    <div className="flex flex-row space-x-4">
                      {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
                      <a
                        className="flex  items-center text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                        href="https://www.honeywasp.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="text-white">Home</span>
                      </a>
                    </div>
                  </div>
                  <div className="hidden sm:block sm:ml-6">
                    <div className="flex flex-row space-x-4">
                      {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
                      <NavLink
                        className="flex  items-center text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                        to="/marketplace"
                      >
                        <span className="text-white">Marketplace</span>
                      </NavLink>
                    </div>
                  </div>
                  <div className="hidden sm:block sm:ml-6">
                    <div className="flex flex-row space-x-4">
                      {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
                      <NavLink
                        className="flex  items-center text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                        to="/leaderboard"
                      >
                        <span className="text-white">Leaderboard</span>
                      </NavLink>
                    </div>
                  </div>
                  <div className="hidden sm:block sm:ml-6">
                    <div className="flex flex-row space-x-4">
                      {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
                      <a
                        className="flex  items-center text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                        href="https://docs.honeywasp.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="text-white">Docs</span>
                      </a>
                    </div>
                  </div>
                  <div className="hidden sm:block sm:ml-6">
                    <div className="flex flex-row space-x-4">
                      {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
                      <a
                        className="flex  items-center text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                        href="https://discord.gg/63avxTdc"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="text-white">Discord</span>
                      </a>
                    </div>
                  </div>
                  <div className="hidden sm:block sm:ml-6">
                    <div className="flex flex-row space-x-4">
                      {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
                      <a
                        className="flex  items-center text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                        href="https://twitter.com/HoneyWaspGame"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="text-white">Twitter</span>
                      </a>
                    </div>
                  </div>

                  {/* <div className="hidden sm:block sm:ml-6">
                    <div className="flex flex-row space-x-4">
                      { Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" }
                      <NavLink className="flex items-center animate-bounce" to="/mint">
                        <span className="text-xl px-3 py-2 rounded-md text-white bg-yellow-400 hover:bg-yellow-600">
                          Mint
                        </span>
                      </NavLink>
                    </div>
                  </div> */}
                </div>
                {/* <div className="hidden sm:block sm:ml-6">
                  <div className="flex flex-row space-x-4">
                    <a
                      className="w-full animate-bounce flex items-center justify-center px-5 py-2 border border-transparent text-base font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                      href="https://quickswap.exchange/#/swap?outputCurrency=0x77ec58f36f3d1a9cf8694fc5c544b04b8c9639dd"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="text-white">Buy HSM</span>
                    </a>
                  </div>
                </div> */}
                <div className="flex  items-center gap-2 h-16">
                  <div className="flex space-x-4 justify-end">
                    <Wallet />
                    {points_unclaimed && (
                      <button
                        type="button"
                        className="flex  items-center justify-center p-2  border-transparent  font-medium rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                        onClick={() => claim(account)}
                      >
                        Claim {points_unclaimed} HON
                      </button>
                    )}
                  </div>
                </div>

                {account && (
                  <div className="hidden sm:ml-6 sm:block">
                    <div className="flex items-center">
                      {/* Profile dropdown */}
                      <span className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                        {balance ? Math.trunc(balance / Math.pow(10, 18)) : 0} HON
                      </span>
                      <Menu as="div" className="ml-3 relative">
                        <div>
                          <Menu.Button className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                            <span className="sr-only">Open user menu</span>
                            <img
                              className="h-8 w-8 rounded-full"
                              src="./assets/avatar.jpg"
                              alt=""
                            />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <Menu.Item>
                              {({ active }) => (
                                <span
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700',
                                  )}
                                >
                                  0x...{account?.slice(35, 42)}
                                </span>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <span
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700',
                                  )}
                                >
                                  Games remaining: {remainingGames ? remainingGames : 0}{' '}
                                </span>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <NavLink
                                  className="block px-4 py-2 text-sm bg-gray-300 rounded-md mx-3 text-gray-700 hover:opacity-90"
                                  to="/dashboard"
                                >
                                  <span>Dashboard</span>
                                </NavLink>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'flex w-full  items-center justify-center p-2 border border-transparent text-base font-medium bg-gray-100 hover:underline focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400',
                                  )}
                                  onClick={disconnect}
                                >
                                  Disconnect
                                </button>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                )}
                <div className="-mr-2 flex sm:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1">
                {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
                <NavLink className="flex items-center animate-bounce" to="/mint">
                  <span className="text-xl px-3 py-2 rounded-md text-white bg-yellow-400 hover:bg-yellow-600">
                    Mint
                  </span>
                </NavLink>
                <NavLink
                  className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                  to="/leaderboard"
                >
                  <span className="text-white">Leaderboard</span>
                </NavLink>

                <NavLink
                  className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                  to="/marketplace"
                >
                  <span className="text-white">Marketplace</span>
                </NavLink>
                <NavLink
                  className="text-gray-300  flex  items-center hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                  to="/"
                >
                  <span className="text-white">Play!</span>
                </NavLink>

                <span className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">
                  Games remaining: {remainingGames ? remainingGames : 0}
                </span>
                {account && (
                  <div className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">
                    <span href="/" className="hidden text-sm font-medium text-white lg:block">
                      0x...{account.slice(35, 42)}
                    </span>
                  </div>
                )}
                <span className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                  {balance ? Math.trunc(balance / Math.pow(10, 18)) : 0} HON
                </span>
                <NavLink
                  className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-base font-medium"
                  to="/dashboard"
                >
                  <span className="text-gray-300">Dashboard</span>
                </NavLink>
              </div>
              <div className="pt-4 pb-3 border-t border-gray-700">
                <div className="flex items-center px-5">
                  <div className="flex-shrink-0">
                    <img className="h-10 w-10 rounded-full" src="./assets/avatar.svg" alt="" />
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium text-white">
                      {account ? `0x...${account.slice(35, 42)}` : 'Not connected'}
                    </div>
                    <div className="text-sm font-medium text-gray-400">
                      {balance ? Math.trunc(balance / Math.pow(10, 18)) : 0} HON
                    </div>
                  </div>
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </header>
  );
};
