import { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { SocketContext } from '../../../../Context';
import { useRemainingGames } from '../../../../Hooks/Api/useRemainingGames';
import { useSmartContractsData } from '../../../../Hooks/Api/useSmartContractsData';
import { useGetUser } from '../../../../Hooks/Api/useGetUser';
import { useGetLevel } from '../../../../Hooks/SmartContracts/useGetLevel';
import useStore from '../../../../Stores/wallet';
import { Popup } from '../../../../Components/Popup';
import { Slider1 } from '../../../../Components/Slider';
import { Skills } from '../Skills';
import { Gears } from '../Gears/Gears';

require('dotenv').config({ path: `.env` });
const socketURL = process.env.REACT_APP_SOCKET_URL;

export const LoginScreen = ({ enterToArena = false }) => {
  const socket = useContext(SocketContext);
  const [gears, setGears] = useState([]);
  const [skills, setSkill] = useState([]);
  const account = useStore((state) => state.account);
  const { data: remainingGames } = useRemainingGames(account);
  const { data: smartContractData } = useSmartContractsData();
  const { data: level = 0 } = useGetLevel({ ...smartContractData });
  const { data: user } = useGetUser(account);
  const [canPlay, setCanPlay] = useState(true);
  const [username, setUsername] = useState(null);
  const [error, setError] = useState(null);
  const [room, setRoom] = useState('default');
  const activeRooms = [];
  const [rooms, setRooms] = useState([]);

  socket.on('error_msg', function (errorMessage) {
    socket.sendBuffer = [];
    setError(errorMessage);
  });

  socket.on('room-data', function (data) {
    socket.sendBuffer = [];
    for (let i = 0; i < data.length; i++) {
      activeRooms.push({ value: data[i].key, name: data[i].type });
    }
    setRooms(activeRooms);
    setRoom(data[0].type);
  });

  socket.on('connect_error', (err) => {
    socket.sendBuffer = [];
    setError(err.message);
    setRooms([]);
  });

  useEffect(() => {
    if (remainingGames) {
      setCanPlay(true);
    }
  }, [remainingGames]);

  useEffect(() => {
    if (account) {
      // If socket connected re-connection
      if (socket.connected) {
        socket.disconnect();
      }
      socket.io.opts.query = { key: account };
      socket.io.opts.forceNew = true;
      socket.connect(socketURL, {
        transports: ['wss'],
        forceNew: true,
      });
    }
  }, [account]);

  const handleChange = (event) => {
    setUsername(event.target.value);
  };

  const selectRoom = (event) => {
    console.log(event.target.value);
    setRoom(event.target.value);
  };

  const joinGame = (e) => {
    e.preventDefault();

    console.log({ account, gears, socket: socket.connected, username });
    if (account && username && gears && socket.connected) {
      socket.emit('join_game', account, username, parseInt(level.level), gears, 'token', room);
      // setCanEnter(true);
      enterToArena(true);
    }
  };

  useEffect(() => {
    if (user) {
      setUsername(user.username);
    }
  }, [user]);

  return (
    <>
      {error && <Popup score="0" message={error} />}
      {/* Hero section */}
      <div>
        <div aria-hidden="true" className="absolute inset-0 overflow-hidden">
          <img
            src="./assets/BeeKnightBackgroundbg.png"
            alt="BeeKnightBackgroundbg"
            className="w-full h-full object-center object-cover"
          />
        </div>
        <div aria-hidden="true" className="absolute inset-0 bg-gray-900 opacity-50" />

        {/* Navigation */}
        <div className="relative max-w-3xl mx-auto py-24 px-6 flex flex-col items-center text-center lg:px-0">
          {account && !error && (
            <>
              {canPlay ? (
                <>
                  <h1 className="text-4xl font-extrabold tracking-tight text-white lg:text-6xl">
                    Ready to fight ?
                  </h1>
                  <p className="mt-4 text-xl text-white">
                    Stay alive as much as possible to maximise your gains. Choose your SKILLS and
                    go!
                  </p>

                  <h1 className="p-4 text-4xl text-yellow-200 justify-items-start">
                    <div>
                      <Gears selectedGears={(selectedGears) => setGears(selectedGears)} />
                    </div>
                    <NavLink to="/marketplace">
                      <button className="w-32 mx-auto flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-700 bg-gray-50	 hover:bg-gray-200	 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-50">
                        Buy gear
                      </button>
                    </NavLink>
                  </h1>

                  {/* {level < 25 && (
                    <NavLink to="/dashboard">
                      <button className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-green-700 bg-green-50	 hover:bg-green-200	 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-50">
                        No skills? Go level up!
                      </button>
                    </NavLink>
                  )} */}

                  <Skills
                    levelData={level}
                    /* selectedSkill={(selectedSkills) => setSkill(selectedSkills)} */
                  />

                  <form className="mt-8 sm:flex" onSubmit={joinGame}>
                    <label htmlFor="username" className="sr-only">
                      Email address
                    </label>

                    <div className="sm:ml-2 mb-2">
                      <input
                        id="username"
                        name="text"
                        type="text"
                        defaultValue={user ? user?.username : ''}
                        maxLength="18"
                        required
                        onChange={handleChange}
                        className="w-full px-5 py-3 placeholder-gray-500 focus:ring-yellow-500 focus:border-yellow-500 sm:max-w-xs border-yellow-300 rounded-md"
                        placeholder="Enter your username"
                      />
                    </div>
                    <div className="sm:ml-2 mb-2">
                      <select
                        id="room"
                        name="room"
                        type="text"
                        required
                        onChange={selectRoom}
                        className="w-full px-7 py-3 placeholder-gray-500 focus:ring-yellow-500 focus:border-yellow-500  border-yellow-300 rounded-md"
                      >
                        {rooms.length > 0 &&
                          rooms.map((option, index) => (
                            <option key={index} value={option.name}>
                              {option.name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                      <button
                        type="submit"
                        className="w-full  flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                      >
                        Enter Arena
                      </button>
                    </div>

                    <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                      <NavLink to="/quests">
                        <button className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-700 bg-gray-50	 hover:bg-gray-200	 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-50">
                          Quests
                        </button>
                      </NavLink>
                    </div>
                  </form>
                </>
              ) : (
                <>
                  <h1 className="text-4xl font-extrabold tracking-tight text-white lg:text-6xl">
                    See you tomorrow !
                  </h1>
                  <br />
                  <h1 className="text-4xl font-extrabold tracking-tight text-white lg:text-6xl">
                    You reach the max number for today
                  </h1>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};
