import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Leaderboard } from './Pages/Leaderboard';
import { Quests } from './Pages/Quests';
import { Marketplace } from './Pages/Marketplace';
import { Sale } from './Pages/Sale';
import { Dashboard } from './Pages/Dashboard';
import { Game } from './Pages/Home';
// import { CountDown1 } from './Components/CountDown';
export const Switch = () => {
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/" element={<Game />} />
        <Route path="/leaderboard" element={<Leaderboard />} />
        {<Route path="/quests" element={<Quests />} />}
        {<Route path="/dashboard" element={<Dashboard />} /> }
        {<Route path="/marketplace" element={<Marketplace />} />}
        {/* {<Route path="/mint" element={<Sale />} />} */}
      </Routes>
    </BrowserRouter>
  );
};
