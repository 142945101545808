import create from 'zustand';
import Web3 from 'web3';
import Onboard from 'bnc-onboard';
require('dotenv').config({ path: `../.env` });
const networkId =
  process.env.NODE_ENV === 'production'
    ? parseInt(process.env.REACT_APP_NETWORK_ID)
    : parseInt(process.env.REACT_APP_TEST_NETWORK_ID);
const networkIdHex =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_NETWORK_ID_HEX
    : process.env.REACT_APP_TEST_NETWORK_ID_HEX;
    
const wallets = [
  //   { walletName: 'coinbase', preferred: true },
  { walletName: 'metamask' },
  {
    walletName: 'walletConnect',
    rpc: { [networkId]: process.env.REACT_APP_HTTPS_ENDPOINT_WEB3 }, // [Optional]
  },

  {
    walletName: 'portis',
    apiKey: process.env.REACT_APP_PORTIS_KEY,
    label: 'Login with Email',
  },
  { walletName: 'torus', label: 'Login with Google/Facebook' },
  { walletName: 'fortmatic', apiKey: process.env.FORTMATIC_KEY },
  { walletName: 'trust', rpcUrl: process.env.REACT_APP_HTTPS_ENDPOINT_WEB3 },
  { walletName: 'authereum' },
  { walletName: 'opera' },
  { walletName: 'operaTouch' },
  { walletName: 'status' },
  { walletName: 'imToken', rpcUrl: process.env.REACT_APP_HTTPS_ENDPOINT_WEB3 },
  { walletName: 'meetone' },
  { walletName: 'mykey', rpcUrl: process.env.REACT_APP_HTTPS_ENDPOINT_WEB3 },
  { walletName: 'huobiwallet', rpcUrl: process.env.REACT_APP_HTTPS_ENDPOINT_WEB3 },
  { walletName: 'hyperpay' },
  { walletName: 'wallet.io', rpcUrl: process.env.REACT_APP_HTTPS_ENDPOINT_WEB3 },
  { walletName: 'atoken' },
  { walletName: 'frame' },
  { walletName: 'ownbit' },
  { walletName: 'alphawallet' },
  { walletName: 'gnosis' },
  { walletName: 'xdefi' },
  { walletName: 'bitpie' },
];
const useStore = create((set) => ({
  account: null,
  library: null,
  wallet: {},
  user_wallet: Onboard({
    dappId: process.env.REACT_APP_BLOCKNATIVE_NOTIFY_API, // [String] The API key created by step one above
    networkId: parseInt(networkId), // [Integer] The Ethereum network ID your Dapp uses.
    walletSelect: { wallets: wallets },
    networkName: process.env.REACT_APP_NETWORK,
    subscriptions: {
      address: async (address) => {
        set({ account: address });
      },
      network: async (_network) => {
        try {
          if (_network !== parseInt(networkId)) {
            await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: networkIdHex }], // chainId must be in hexadecimal numbers
            });
          }
        } catch (switchError) {
          // This error code indicates that the chain has not been added to MetaMask.
          console.log(switchError.code);
          if (switchError.code === 4902) {
            try {
              await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [
                  {
                    chainId: networkIdHex,
                    chainName: process.env.REACT_APP_NETWORK_NAME,
                    nativeCurrency: {
                      name: process.env.REACT_APP_TOKEN_NAME,
                      symbol: process.env.REACT_APP_TOKEN_SYMBOL,
                      decimals: 18,
                    },
                    blockExplorerUrls: [process.env.REACT_APP_BLOCKEXPLORER_URL],
                    rpcUrls: [process.env.REACT_APP_RPC_URL],
                  },
                ],
              });
            } catch (addError) {
              // handle "add" error
            }
          }
        }
      },
      wallet: (wallet) => {
        set({ library: new Web3(wallet.provider) });
        window.localStorage.setItem('selectedWallet', wallet.name);
      },
    },
  }),
}));
export default useStore;
