// @flow
export const WalletNotConnected = ({ text1, text2 }) => {
  return (
    <div className="pt-12 sm:pt-16 lg:pt-24">
      <div className="max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto space-y-2 lg:max-w-none">
          {text1 && (
            <h2 className="text-lg leading-6 font-semibold text-gray-300 uppercase tracking-wider">
              {text1}
            </h2>
          )}
          {text2 && (
            <p className="text-3xl font-extrabold text-white sm:text-4xl lg:text-5xl">{text2}</p>
          )}
        </div>
      </div>
    </div>
  );
};
