import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { ArrowCircleLeftIcon } from '@heroicons/react/solid';
import { ArrowCircleRightIcon } from '@heroicons/react/solid';
import { ArrowCircleUpIcon } from '@heroicons/react/solid';
import { ArrowCircleDownIcon } from '@heroicons/react/solid';
import { CursorClickIcon } from '@heroicons/react/solid';

const features = [
  {
    name: 'Gameplay',
    description:
      'You are the only wasp (https://en.wikipedia.org/wiki/Brachygastra_mellifica) that can grab honey, kill all the flies and survive as long as you can. Top players are rewarded every month with $HON',
  },
  {
    name: 'Butterfly',
    description: 'Butterfly gives you a ton of $HON.',
  },
  {
    name: 'Honey',
    description: 'You can restore yourself by grabbing some honey.',
  },
  {
    name: 'Reward',
    description: 'Top players are rewarded every months with $HON',
  },
  {
    name: 'Daily games',
    description: 'Every day, you only have 10 games (based on your Radiony NFT). ',
  },
  {
    name: 'NFT',
    description: 'Use your $HON to improve your gear by buying NFT (speed, armor, fire rate)',
  },
  {
    name: 'Empty wallet ?',
    description:
      'You can get free $Matic on https://matic.supply/ or ask some on the chanel matic-airdrop in https://discord.gg/aXGpJDRN',
  },
];

export const Slider = () => {
  const tutorialDone = window.localStorage.getItem('tutorialDone');
  const [open, setOpen] = useState(true && !!!tutorialDone);
  return (
    <>
      {!open && (
        <div className="m-4 absolute left-0">
          <button
            type="button"
            className="flex  items-center justify-center p-2  border-transparent  font-medium rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:col-start-1 sm:text-sm"
            onClick={() => {
              setOpen(true);
            }}
          >
            Help
          </button>
        </div>
      )}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="fixed  " onClose={() => {}}>
          <div className="absolute  ">
            <Dialog.Overlay className="absolute" />

            <div className="fixed inset-y-0 right-0 pl-5 max-w-full flex">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="w-screen max-w-xs">
                  <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900">
                          How to play
                        </Dialog.Title>
                        <div className="ml-3 h-7 flex items-center">
                          <button
                            type="button"
                            className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={() => {
                              window.localStorage.setItem('tutorialDone', true);
                              setOpen(false);
                            }}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="mt-6 relative flex-1 px-4 sm:px-6">
                      {/* Replace with your content */}
                      <div>
                        <div className="border-b border-gray-200 pb-10">
                          <p className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                            Rules
                          </p>
                        </div>

                        <dl className="mt-10 space-y-10">
                          <div>
                            <dt className="text-sm font-medium text-gray-900">Keyboard</dt>
                            <ul className="mt-6 space-y-4">
                              <li className="flex space-x-3">
                                <ArrowCircleUpIcon
                                  className="flex-shrink-0 h-5 w-5 text-gray-500"
                                  aria-hidden="true"
                                />
                                <span className="text-sm text-gray-500">W = Move Up</span>
                              </li>
                              <li className="flex space-x-3">
                                <ArrowCircleDownIcon
                                  className="flex-shrink-0 h-5 w-5 text-gray-500"
                                  aria-hidden="true"
                                />
                                <span className="text-sm text-gray-500">S = Move down</span>
                              </li>
                              <li className="flex space-x-3">
                                <ArrowCircleLeftIcon
                                  className="flex-shrink-0 h-5 w-5 text-gray-500"
                                  aria-hidden="true"
                                />
                                <span className="text-sm text-gray-500">A = Move left</span>
                              </li>
                              <li className="flex space-x-3">
                                <ArrowCircleRightIcon
                                  className="flex-shrink-0 h-5 w-5 text-gray-500"
                                  aria-hidden="true"
                                />
                                <span className="text-sm text-gray-500">D Move right</span>
                              </li>
                              <li className="flex space-x-3">
                                <span className="text-sm text-gray-500">Space = Teleport</span>
                              </li>
                              <li className="flex space-x-3">
                                <CursorClickIcon
                                  className="flex-shrink-0 h-5 w-5 text-gray-500"
                                  aria-hidden="true"
                                />
                                <span className="text-sm text-gray-500">
                                  Click : Charge up to 6 bullets
                                </span>
                              </li>
                            </ul>
                          </div>
                          {features.map((feature) => (
                            <div key={feature.name}>
                              <dt className="text-sm font-medium text-gray-900">{feature.name}</dt>
                              <dd className="mt-3 text-sm text-gray-500">{feature.description}</dd>
                            </div>
                          ))}
                        </dl>
                      </div>
                      {/* /End replace */}
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
