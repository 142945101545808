import { useQuery } from 'react-query';
import useStore from '../../Stores/wallet';

export const useGetLevel = ({ smart_contract_address_level, abi_level }) => {
  const account = useStore((state) => state.account);
  const library = useStore((state) => state.library);

  return useQuery(
    ['useGetLevel', account, abi_level],
    async () => {
      const LevelContract = new library.eth.Contract(abi_level, smart_contract_address_level);
      // const level = await LevelContract.methods.level().call();
      const level = await LevelContract.methods.levelByAddress(account).call();
      const cost = await LevelContract.methods.costToLevel(parseInt(level)).call();
      return { cost, level };
    },
    {
      // The query will not execute until this fiels exists
      enabled: !!account && !!abi_level && !!library && !!smart_contract_address_level,
    },
  );
};
