import React from 'react';

export const Leveling = ({ level }) => {
  let steps = [
    { id: 'S1', title: 'teleport', status: 'upcoming' },
    { id: 'S2', title: 'level 50 Nova ', status: 'upcoming' },
    { id: 'S3', title: 'level 75 shield', status: 'upcoming' },
    { id: 'S4', title: 'upcoming', status: 'upcoming' },
  ];

  if (level >= 25 && level < 50) {
    steps = steps.map((step) => {
      if (step.id === 'S1') return { ...step, status: 'done' };
      return { ...step, status: 'upcoming' };
    });
  }

  if (level >= 50 && level < 75) {
    steps = steps.map((step) => {
      if (step.id === 'S1' || step.id === 'S2') return { ...step, status: 'done' };
      return { ...step, status: 'upcoming' };
    });
  }

  if (level >= 75) {
    steps = steps.map((step) => {
      if (step.id === 'S4') return { ...step, status: 'upcoming' };
      return { ...step, status: 'done' };
    });
  }

  const position = level < 100 ? level : 100;

  return (
    <div className="mx-auto p-1 rounded-lg ">
      {/* :PROGRESS BAR */}
      <div className="relative mb-1 h-5 rounded overflow-hidden bg-gray-300">
        <span
          className="absolute top-0 left-0 z-10 inline-block h-full bg-gradient-to-r from-green-200 to-green-600 bg-opacity-60 transition-all duration-300 ease-in"
          style={{ width: `${position}%` }}
        />
      </div>

      {/* :PROGRESS STEPS */}
      <nav className="mt-4 sm:mt-0 mx-auto px-4" aria-label="Progress steps">
        <ol className="grid grid-flow-col auto-cols-fr gap-5">
          {steps.map((step, index) => {
            return (
              <li
                key={step.id}
                className={`col-span-full sm:col-auto ${
                  index !== 0 && 'sm:border-l-2 border-gray-100'
                }`}
              >
                {/* :STATUS done */}
                {step.status === 'done' && (
                  <p className="group p-3 flex sm:justify-center items-center border-l-2 sm:border-none border-green-200 hover:border-green-400">
                    <span className="text-center text-sm lg:text-base text-green-300 font-bold capitalize group-hover:text-green-400">
                      {step.title}
                    </span>
                  </p>
                )}

                {/* :STATUS done */}
                {/* {step.status === 'done' && (
                  <a
                    href={step.href}
                    className="group p-3 flex sm:justify-center items-center border-l-2 sm:border-none border-blue-500"
                  >
                    <span className="text-center text-sm lg:text-base text-blue-500 font-bold capitalize group-hover:text-blue-600">
                      {step.title}
                    </span>
                  </a>
                )} */}

                {/* :STATUS UPCOMING */}
                {step.status === 'upcoming' && (
                  <p className="group p-3 flex sm:justify-center items-center border-none">
                    <span className="text-center text-sm lg:text-base text-gray-400 font-bold capitalize group-hover:text-gray-600">
                      {step.title}
                    </span>
                  </p>
                )}
              </li>
            );
          })}
        </ol>
      </nav>
    </div>
  );
};
