module.exports.default = Object.freeze({
  PLAYER_RADIUS: 15,
  BOSS_RADIUS: 80,
  PLAYER_RADIUS_PROTECT: 20,
  PLAYER_MAX_HP: 400,
  OBSTACLE_MAX_HP: 1000,
  PLAYER_MAX_PROTECT: 50,
  AOE_RADIUS: 75,
  PLAYER_SPEED: 200,
  ANIMATION_COOLDOWN: {
    AOE: 500,
    SHIELD: 3000,
  },
  COOLDOWN: {
    AOE: 2000,
    TELEPORT: 1500,
    SHIELD: 8000,
  },
  SKILLS_MINIMUM_LEVELS: {
    AOE: 50,
    SHIELD: 75,
  },

  BONUS_RADIUS: 15,
  OBSTACLE_RADIUS: 20,

  PLAYER_FIRE_COOLDOWN: 0.37,
  MAXIMUM_BULLET_RELOAD: 6,
  BULLET_RADIUS: 15,
  BULLET_SPEED: 400,
  STACKED_BULLET_SPEED: 1350,
  BULLET_DAMAGE: 15,
  AOE_DAMAGE: 45,

  SCORE_BULLET_HIT: 5,
  SCORE_BULLET_HIT_GOBLIN_MULTIPLIER: 5,
  SCORE_BULLET_HIT_HUMAN_MULTIPLIER: 10,
  SCORE_BULLET_HIT_BOSS_MULTIPLIER: 50,
  SCORE_BULLET_KILL_MULTIPLIER: 2,
  SCORE_BULLET_BOSS_KILL_MULTIPLIER: 100,

  SCORE_PER_SECOND: 0,
  BOOST_HEAL: 125,
  BOOST_POWERUP: 75,
  BOOST_DURATION: 20000,
  SCORE_HEAL_TAKEN: 20,
  SCORE_ARMOR_TAKEN: 20,
  PLATFORM_DAMAGE: 100,

  BOT_RESPAWN_DURATION: 15000,
  GOBLIN_RESPAWN_DURATION: 25000,
  OBSTACLE_RESPAWN_DURATION: 90000,
  HEAL_RESPAWN_DURATION: 60000,
  POWERUP_RESPAWN_DURATION: 45000,
  FLAME_RESPAWN_DURATION: 60000,

  MAX_BOTS_ON_MAP: 10,
  MAX_OBSTACLE_ON_MAP: 2,
  MAX_HEALS_ON_MAP: 15,
  MAX_ARMOR_ON_MAP: 15,
  MAX_FLAME_ON_MAP: 5,
  MAX_POWERUP_ON_MAP: 5,

  MAX_DAY_GAMES: 20,
  MAX_PLAYER_IN_A_ROOM: 100,
  MAP_SIZE: 2000,
  MSG_TYPES: {
    AOE: 'aoe',
    SHIELD: 'shield',
    JOIN_GAME: 'join_game',
    GAME_UPDATE: 'update',
    INPUT: 'input',
    GAME_OVER: 'dead',
    MSG: 'MSG',
    STORE_TX_RESULT: 'store_tx_result',
    UPDATE_POSITION: 'update_position',
    KEYBOARD_INPUT: 'keyboard_input',
    MOUSE_INPUT: 'mouse_input',
    MOUSE_CLICK: 'mouse_click',
    CONNECT_ERROR: 'connect_error',
    TIMELEFT: 'timeleft',
  },

  GAME_STATE: {
    // PVP: {
    //   min_user: 1,
    //   max_user: 2,
    //   queue: true,
    //   name: 'PVP',
    // },
    // BOSS: {
    //   min_user: 1,
    //   max_user: 10,
    //   queue: false,
    //   name: 'BOSS',
    // },

    PVE: {
      min_user: 1,
      max_user: 100,
      queue: false,
      name: 'PVE',
    },
    BATTLE_ROYALE: {
      min_user: 2,
      max_user: 10,
      queue: true,
      queue_time: 60000,
      event_duration: 600000,
      name: 'BATTLE_ROYALE',
      reward: 50000,
    },
  },
});
