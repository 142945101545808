import { isMobile } from 'react-device-detect';
import { MiniCountDown } from '../../../Components/CountDown';
import constants from '../../../shared/constants';
import { useGameState } from '../../../Hooks/Socketio/useGameState';
import { useStartAt } from '../../../Hooks/Socketio/useStartAt';
const { GAME_STATE } = constants.default;

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
export const EventsAnnouncement = () => {
  const { gameState } = useGameState();
  const { startAt } = useStartAt();
  const display = isMobile ? `m-8 absolute sm:top-0 top-0 left-1/2` : `m-8 absolute bottom-0`;
  if (gameState?.gameState === 'BATTLE_ROYALE') {
    return (
      <div>
        <div className={classNames(display)}>
          {!gameState?.isStarted ? (
            <>
              <p className="font-black pb-3 text-2xl sm:text-4xl lg:text-5xl  leading-9 text-center text-white">
                Minimum players to start game: {gameState.nbOfPlayers} /{' '}
                {GAME_STATE.BATTLE_ROYALE.min_user}
              </p>
              {startAt > 0 && <MiniCountDown startAt={startAt} message="Game start in " />}
            </>
          ) : (
            <>
              {gameState?.endAt > 0 && (
                <MiniCountDown startAt={gameState.endAt} message="Battle Royal end in" />
              )}
            </>
          )}
        </div>
      </div>
    );
  }
  if (gameState?.gameState === 'PVE') {
    return (
      <div>
        <div className={classNames(display)}>
          <p className="font-black pb-3 text-2xl sm:text-4xl lg:text-5xl  leading-9 text-center text-white"></p>
          {startAt > 0 && <MiniCountDown message="Boss appears in " />}
        </div>
      </div>
    );
  }
  return null;
};
