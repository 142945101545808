export const Kills = ({ killed, killer }) => {
  return (
    <h2 className="text-5xl  font-extrabold   text-secondary tracking-tight animate-bounce ">
      <span className="relative   inline-block px-2 ">
        <div className="absolute inset-0 transform -skew-x-12 bg-gray-900" />
        <span className="relative  text-yellow-500">{killer}</span>
        <span className="relative  text-white"> {`-> `}</span>
        <span className="relative  text-red-700">KILL</span>
        <span className="relative  text-white"> {`-> `}</span>
        <span className="relative  text-white">{killed}</span>
      </span>
    </h2>
  );
};
