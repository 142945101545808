import axios from 'redaxios';
import { useQuery } from 'react-query';
require('dotenv').config({ path: `.env` });
const apiUrl = process.env.REACT_APP_API_URL;

export const useKillCounts = () => {
  return useQuery(['useKillCounts'], () =>
    axios
      .get(`${apiUrl}/kill-counts`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((res) => res.data.payload),
  );
};
