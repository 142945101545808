import { useQuery } from 'react-query';
import useStore from '../../Stores/wallet';
export const useCheckAllowance = (
  allowed_smart_contract_address,
  abi_token,
  smart_contract_address_token
) => {
  const account = useStore((state) => state.account); 
  const library = useStore((state) => state.library);

  return useQuery(
    ['useCheckAllowance', abi_token, smart_contract_address_token, allowed_smart_contract_address],
    async () => {
      const TokenContract = new library.eth.Contract(abi_token, smart_contract_address_token);
      const allowance = await TokenContract.methods
        .allowance(account, allowed_smart_contract_address)
        .call();
      if (allowance > 1000000000000000) {
        return true;
      }
      return false;
    },
    {
      // The query will not execute until the userId exists
      enabled: !!abi_token && !!smart_contract_address_token && !!library && !!account,
    },
  );
};
