import axios from 'redaxios';
import { useQuery } from 'react-query';
require('dotenv').config({ path: `.env` });
const apiUrl = process.env.REACT_APP_API_URL;
export const useRemainingGames = (pkey) => {
  return useQuery(
    ['useRemainingGames', pkey],
    () =>
        axios
          .get(`${apiUrl}/remaining-games/${pkey}`, {
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then((res) => res.data.payload)
          .catch((error) => {
              localStorage.clear();
              window.location.reload();
          }),
    {
      // The query will not execute until the userId exists
      enabled: !!pkey,
    },
  );
};
