import { SocketContext } from '../../Context/index';
import { useState, useEffect, useContext } from 'react';
import CONSTANTS from '../../shared/constants.js';
const { MSG_TYPES } = CONSTANTS.default;

export function useGameState() {
  const [gameState, setGameState] = useState(null);
  const socket = useContext(SocketContext);

  useEffect(() => {
    if (!socket) return;

    socket.on(MSG_TYPES.MSG, (data) => {
      if (data.type === 'PLAYER_IN_GAME') setGameState(data);
    });
  }, [socket]);

  return { gameState };
}
