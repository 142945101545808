import React, { useRef, useEffect, useState, useCallback } from 'react';
import CONSTANTS from '../../../shared/constants.js';
import { Joystick } from 'react-joystick-component';
import { isMobile } from 'react-device-detect';
import { throttle } from 'throttle-debounce';
import { SocketContext, AssetsContext } from '../../../Context';
import { interpolateObject, interpolateObjectArray } from './state';
import {
  renderBackground,
  renderPlayer,
  renderBullet,
  renderBonuses,
  renderObstacles,
} from './render.js';
const { MSG_TYPES } = CONSTANTS.default;
let keyPressedList = [];
let keyCode = { z: 87, d: 68, q: 65, s: 83 };
let lastStateKeyboard = 0;
let lastStateMouseClick = 0;
const keyConverter = {
  81: 65,
  90: 87,
  83: 83,
  68: 68,
  87: 87,
  65: 65,
  37: 65,
  38: 87,
  39: 68,
  40: 83,
};
/**
 * azerty -> qwerty
 *  q=81  ->  a=65 // major change
 *  z=90  ->  q=87 // major change
 *  s=83  ->  q=83
 *  d=83  ->  q=68
 *  <-    =   a=65
 *  up    =   q=87
 *  ->    =   q=68
 * down   =   q=83
 */
let mainDir = {
  up: { x: 1, y: window.innerHeight, key: 'z', code: 87 },
  down: { x: 1, y: -window.innerHeight, key: 's', code: 83 },
  left: { x: -window.innerWidth, y: 1, key: 'q', code: 65 },
  right: { x: window.innerWidth, y: 1, key: 'd', code: 68 },
};

const eventListeners = {};
const windowListener = window.addEventListener;

window.addEventListener = (type, fn, options) => {
  if (!eventListeners[type]) {
    eventListeners[type] = [];
  }

  eventListeners[type].push(fn);

  return windowListener(type, fn, options);
};

const removeEventListenersByType = function (type) {
  console.log(type);
  if (!eventListeners[type] || !eventListeners[type].length) {
    return;
  }

  for (let i = 0; i < eventListeners[type].length; i++) {
    window.removeEventListener(type, eventListeners[type][i]);
  }

  delete eventListeners[type];
};

const removeListenersForGame = (override = []) => {
  const types =
    override.length > 0
      ? override
      : ['keypress', 'keydown', 'keyup', 'mousedown', 'mouseup', 'mousemove'];
  types.map(removeEventListenersByType);
};

function getBrowserVisibilityProp() {
  const types = [];
  types['hidden'] = 'visibilitychange';
  types['msHidden'] = 'msvisibilitychange';
  types['webkitHidden'] = 'webkitvisibilitychange';
  return types[getBrowserDocumentHiddenProp()] || null;
}

function getBrowserDocumentHiddenProp() {
  const types = ['hidden', 'msHidden', 'webkitHidden'];

  for (let i = 0; i < types.length; i++) {
    if (typeof document[types[i]] !== 'undefined') {
      return types[i];
    }
  }

  return null;
}

function getIsDocumentHidden() {
  return !document[getBrowserDocumentHiddenProp()];
}

export const Canvas = ({ onGameOver, onKillPlayer }) => {
  const socket = React.useContext(SocketContext);
  const assets = React.useContext(AssetsContext);
  const canvasRef = useRef();
  const gameStartRef = useRef();
  const firstServerTimestampRef = useRef();
  const gameUpdatesRef = useRef([]);

  const [me, setMe] = useState(null);
  const [dirMoving, setDirMoving] = useState(null);
  const [others, setOthers] = useState(null);
  const [bullets, setBullets] = useState(null);
  const [obstacles, setObstacles] = useState(null);
  const [heals, setHeals] = useState(null);
  const [protects, setProtects] = useState(null);
  const [platforms, setPlatforms] = useState(null);
  const [isVisible, setIsVisible] = useState(getIsDocumentHidden());

  const installMouseActions = () => {
    console.log('mouse actions installing');
    window.addEventListener('mousedown', onClickInput);
    window.addEventListener('mouseup', onClickInput);
    window.addEventListener('mousemove', onMouseInput);
  };

  const installKeyboardActions = () => {
    console.log('keyboard actions installing');
    window.addEventListener('keypress', onKeyboardInput);
    window.addEventListener('keydown', onKeyboardPress);
    window.addEventListener('keyup', onKeyboardRelease);
  };

  const onVisibilityChange = () => {
    if (getBrowserVisibilityProp() !== null) {
      const status = getIsDocumentHidden();
      console.log(status);
      if (!status) {
        lastStateMouseClick = false;
        keyPressedList = [];
        removeListenersForGame(['mousemove', 'mouseup', 'mousedown']);

        if (socket?.connected) {
          socket.emit(MSG_TYPES.KEYBOARD_INPUT, 0, 0);
          socket.emit(MSG_TYPES.MOUSE_CLICK, 0);
        }
      } else {
        if (
          socket.connected &&
          !eventListeners['mousemove'] &&
          !eventListeners['mouseup'] &&
          !eventListeners['mousedown']
        ) {
          installMouseActions();
        }
      }
      setIsVisible(status);
    }
  };

  const RENDER_DELAY = 100;

  const [time, setTime] = useState(Date.now());

  const onTouchStick = throttle(25, (position) => {
    const dir = Math.atan2(position.x, position.y);
    setDirMoving(dir);
    if (socket?.connected) socket.emit(MSG_TYPES.KEYBOARD_INPUT, dir, 1);
  });

  const onReleaseTouchStick = useCallback(() => {
    if (socket?.connected) socket.emit(MSG_TYPES.KEYBOARD_INPUT, 0, 0);
  }, []);

  const onMouseInput = useCallback((event) => {
    const dir = Math.atan2(
      event.clientX - window.innerWidth / 2.05,
      window.innerHeight / 2.05 - event.clientY,
    );

    // to check
    //console.log({ isMobile });
    if (!isMobile) updateMouseDirection(dir);
  }, []);

  const onKeyboardInput = useCallback((event) => {
    if (event.keyCode === 32) {
      updatePosition(event); // space
    }
    if (event.keyCode === 114) {
      talentInput(MSG_TYPES.AOE); // R
    }
    if (event.keyCode === 101) {
      talentInput(MSG_TYPES.SHIELD); // E
    }
  }, []);

  const addKeyPressedList = (_keyPressed) => {
    if (!keyPressedList.find((value) => value === _keyPressed)) {
      if (keyPressedList.length === 4) {
        keyPressedList.shift();
      }
      keyPressedList.push(_keyPressed);
    }
  };

  const removeKeyPressedList = (_keyPressed) => {
    keyPressedList.splice(
      keyPressedList.findIndex((value) => value === _keyPressed),
      1,
    );
  };

  const computeDirection = (_keyCode) => {
    if (keyPressedList.length === 0) {
      updateDirection(0);
      return;
    }

    const firstDirectionName = Object.keys(mainDir).find(
      (value) => mainDir[value].code === keyPressedList[0],
    );
    let dir = Math.atan2(mainDir[firstDirectionName].x, mainDir[firstDirectionName].y);

    if (keyPressedList.length === 2) {
      const secondDirectionName = Object.keys(mainDir).find(
        (value) => mainDir[value].code === keyPressedList[1],
      );
      dir = Math.atan2(
        mainDir[secondDirectionName].x + mainDir[firstDirectionName].x,
        mainDir[secondDirectionName].y + mainDir[firstDirectionName].y,
      );
    }

    updateDirection(dir);
  };

  const onKeyboardPress = useCallback((event) => {
    // converting
    const _keyCode = keyConverter[event.keyCode];
    if (
      _keyCode === keyCode['q'] ||
      _keyCode === keyCode['d'] ||
      _keyCode === keyCode['z'] ||
      _keyCode === keyCode['s']
    ) {
      addKeyPressedList(_keyCode);

      computeDirection(_keyCode);
    }
  }, []);

  const onKeyboardRelease = useCallback((event) => {
    // converting
    const _keyCode = keyConverter[event.keyCode];
    if (
      _keyCode === keyCode['q'] ||
      _keyCode === keyCode['d'] ||
      _keyCode === keyCode['z'] ||
      _keyCode === keyCode['s']
    ) {
      removeKeyPressedList(_keyCode);
      computeDirection(_keyCode);
    }
  }, []);

  const onClickInput = useCallback((event) => {
    const dir = Math.atan2(
      event.clientX - window.innerWidth / 2,
      window.innerHeight / 2 - event.clientY,
    );

    if (!isMobile) updateMouseClick(dir);
  }, []);

  const currentServerTime = () => {
    return firstServerTimestampRef.current + (Date.now() - gameStartRef.current) - RENDER_DELAY;
  };

  useEffect(() => {
    const framePerSecond = 100;
    const interval = setInterval(() => setTime(Date.now()), 1000 / framePerSecond);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const getBaseUpdate = () => {
    const serverTime = currentServerTime();
    if (!gameUpdatesRef.current) {
      return;
    }
    let gameUpdates = gameUpdatesRef.current;
    for (let i = gameUpdates.length - 1; i >= 0; i--) {
      if (gameUpdates[i].t <= serverTime) {
        return i;
      }
    }
    return -1;
  };

  const onProcessGame = (update) => {
    if (!gameUpdatesRef.current) {
      return;
    }
    let gameUpdates = gameUpdatesRef.current;
    gameUpdates.push(update);

    // Keep only one game update before the current server time
    const base = getBaseUpdate();

    if (base > 0) {
      gameUpdates.splice(0, base);
    }
  };

  const onGetCurrentState = () => {
    const serverTime = currentServerTime();
    const base = getBaseUpdate();
    if (!gameUpdatesRef.current) {
      return;
    }
    let gameUpdates = gameUpdatesRef.current;

    if (base < 0) {
      return;
    }

    if (base === gameUpdates.length - 1) {
      const baseUpdate = gameUpdates[base];

      setMe(baseUpdate.me);
      setOthers(baseUpdate.others);
      setBullets(baseUpdate.bullets);
      setObstacles(baseUpdate.obstacles);
      setHeals(baseUpdate.heals);
      setProtects(baseUpdate.protects);
      setPlatforms(baseUpdate.platforms);
      return;
    }

    const baseUpdate = gameUpdates[base];
    const next = gameUpdates[base + 1];
    const ratio = (serverTime - baseUpdate.t) / (next.t - baseUpdate.t);

    setMe(interpolateObject(baseUpdate.me, next.me, ratio));
    setOthers(interpolateObjectArray(baseUpdate.others, next.others, ratio));
    setBullets(interpolateObjectArray(baseUpdate.bullets, next.bullets, ratio));
    setObstacles(interpolateObjectArray(baseUpdate.obstacles, next.obstacles, ratio));
    setHeals(interpolateObjectArray(baseUpdate.heals, next.heals, ratio));
    setProtects(interpolateObjectArray(baseUpdate.protects, next.protects, ratio));
    setPlatforms(interpolateObjectArray(baseUpdate.platforms, next.platforms, ratio));
  };

  // useEffect(() => {
  //   socket.on('dead_player', (players) => {
  //     onKillPlayer(players);
  //   });
  // }, [socket]);

  useEffect(() => {
    console.log('socket installed');

    document.body.classList.add('overflow-hidden');

    if (canvasRef.current) {
      canvasRef.current.oncontextmenu = function (e) {
        // Disabled context menu
        e.preventDefault();
      };
    }

    installKeyboardActions();
    installMouseActions();

    socket.on('dead_player', (players) => {
      onKillPlayer(players);
    });

    socket.on(MSG_TYPES.GAME_OVER, (score) => {
      removeListenersForGame();
      socket.disconnect();
      onGameOver(true, score, '');
    });

    socket.on(MSG_TYPES.ERROR_MSG, (message) => {
      socket.sendBuffer = [];
      removeListenersForGame();
      socket.disconnect();
      onGameOver(true, 0, message);
    });

    socket.on(MSG_TYPES.CONNECT_ERROR, (err) => {
      //TODO: set this function for user can't connect the socket
      socket.sendBuffer = [];
      removeListenersForGame();
      socket.disconnect();
      //console.log(err.message); // not authorized
      onGameOver(true, 0, err.message);
    });

    socket.on(MSG_TYPES.GAME_UPDATE, (update) => {
      if (!firstServerTimestampRef.current || !gameStartRef.current) {
        firstServerTimestampRef.current = update.t;
        gameStartRef.current = Date.now();
      }

      onProcessGame(update);
    });
  }, [socket]);

  const painting = () => {
    if (!canvasRef?.current) {
      return;
    }
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    //Our first draw
    context.fillRect(0, 0, context.canvas.width, context.canvas.height);

    //Set dimension canvas
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    if (me) {
      renderBackground(me, canvas, assets);
      renderPlayer(me, me, canvas, assets);
    }

    // // Draw me

    if (bullets) {
      // // Draw all bullets
      bullets.forEach((bullet) => {
        renderBullet(me, bullet, canvas, assets);
      });
    }

    if (obstacles) renderObstacles(me, obstacles, canvas, assets);
    if (heals) renderBonuses(me, heals, 'heal', canvas, assets);
    if (protects) renderBonuses(me, protects, 'speedup', canvas, assets);
    if (platforms) renderBonuses(me, platforms, 'takedmg', canvas, assets);

    // Draw all players
    if (others && others?.length > 0) {
      others.forEach((player) => {
        renderPlayer(me, player, canvas, assets);
      });
    }
  };

  useEffect(() => {
    onGetCurrentState();
    painting();
  }, [time]);

  const updateMouseDirection = throttle(25, (dir) => {
    if (lastStateMouseClick && socket?.connected) socket.emit(MSG_TYPES.MOUSE_INPUT, dir);
  });

  const updateMouseClick = throttle(20, (dir) => {
    lastStateMouseClick ? (lastStateMouseClick = false) : (lastStateMouseClick = true);
    if (socket?.connected) socket.emit(MSG_TYPES.MOUSE_CLICK, dir);
  });

  const updateDirection = throttle(20, (dir) => {
    const moving = keyPressedList.length > 0 ? 1 : 0; // check if moving or not
    if (lastStateKeyboard !== keyPressedList.length) {
      lastStateKeyboard = keyPressedList.length;
      if (socket?.connected) socket.emit(MSG_TYPES.KEYBOARD_INPUT, dir, moving);
    }
  });

  const talentInput = throttle(20, (event) => {
    if (socket?.connected) socket.emit(event, event);
  });

  const updatePosition = throttle(20, (keyPress) => {
    if (socket?.connected) socket.emit(MSG_TYPES.UPDATE_POSITION, keyPress.charCode);
  });

  useEffect(() => {
    const property = getBrowserVisibilityProp();

    document.addEventListener(property, onVisibilityChange, false);

    return () => {
      document.removeEventListener(property, onVisibilityChange);
    };
  });

  return (
    <>
      <div className="mr-10 mb-4 absolute bottom-0 left-0 ">
        {isMobile && (
          <>
            <Joystick
              size={100}
              sticky={true}
              baseColor="green"
              stickColor="blue"
              move={(event) => {
                console.log({ event });

                if (event) {
                  onTouchStick(event);
                }
              }}
              stop={(stop) => {
                if (stop) onReleaseTouchStick(stop);
              }}
            />
          </>
        )}
      </div>
      <div className="ml-10 mb-4 absolute bottom-0 right-0 ">
        {isMobile && (
          <>
            <Joystick
              size={100}
              sticky={true}
              baseColor="red"
              stickColor="blue"
              move={(event) => {
                if (event && socket?.connected) {
                  const dir = Math.atan2(event.x, event.y);
                  updateMouseClick(dir);
                  updateMouseDirection(dir);
                }
              }}
              stop={(stop) => {
                if (stop) updateMouseClick(0);
              }}
            />
          </>
        )}
      </div>
      <canvas ref={canvasRef} />
    </>
  );
};
